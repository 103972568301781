import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './info-card.scss';

const InfoCard = (props) => {
	const {
		title = '',
		headerText = '',
		contentList, 
		headerList = [], 
		closeable = false, 
		closeDialogue, 
		collapseable = false,
	} = props;

	const [collapsed, setCollapsed] = useState(collapseable);

	return (
		<div className='InfoCard'>
			{/* Header */}
			<div className={'InfoCard-header' + (collapsed ? '' : ' expanded')}>
				<div className={title === '' ? 'InfoCard-headerText' : 'InfoCard-title'}>
					{title === '' ? headerText : title}
				</div>
				{headerList.map((item, index) => {
					return <div key={index} className='InfoCard-headerItem'>{item}</div>;
				})}
				{closeable ? <div className='InfoCard-closeButton' onClick={() => {closeDialogue();}}/> : ''}
				{collapseable ? 
					<div className={'InfoCard-toggleButton' + (collapsed ? ' closed' : ' expanded')}
						onClick={() => {setCollapsed(!collapsed);}}/> : ''}
			</div>

			{/* Body */}
			<div className={'InfoCard-body' + (collapsed ? '' : ' expanded')}>
				{contentList.map((item, index, arr) => {
					let className = 'InfoCard-item';

					className += (arr.length - 1) === index ? ' NoBorder' : '';

					let isExpensive = false;
					if (item.hasOwnProperty('isExpensive')) {
						isExpensive = item.isExpensive;
					}

					return (
						<div key={index} className={className}>
							
							<div className='InfoCard-titleWrapper'>
								<div className='InfoCard-title'>
									{item.title}
								</div>
								{isExpensive ? <div className='InfoCard-isExpensive'/> : ''}
							</div>
							<div className='InfoCard-description'>{item.description}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

InfoCard.propTypes = {
	headerText: PropTypes.string,
	title: PropTypes.string,
	contentList: PropTypes.array.isRequired,
	headerList: PropTypes.array,
	closeable: PropTypes.bool,
	closeDialogue: PropTypes.func,
	collapseable: PropTypes.bool,
};

export default InfoCard;