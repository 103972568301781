import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './vote-result.scss';
import { pageUiTexts, voteTexts, continueButtonTexts, generalUiTexts } from 'data/ui-texts';
import NextPageButton from 'components/ui/next-page-button/next-page-button';
import { getNewAreaPoints } from 'helpers/area-data-helper';
import GameCode from 'components/ui/game-code/game-code';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import { pointTypes } from 'data/debate-data';
import Area from 'components/ui/area/area';
import { rules } from 'data/debate-data';
import appConfig from 'config/app.config';
import PdfLink from 'components/ui/pdf-link/pdf-link';
import teachersGuide from 'assets/pdfs/lærervejledning.pdf';

const VoteResult = (props) => {
	const {
		handleGoToGame,
		handleGoToGameStep,
		game,
		gameCode,
		gameStepData,
		debateData,
		topVotes,
		allVotes,
	} = props;

	const [showPopup, setShowPopup] = useState(false);

	const [currentSubAreaIndex, setCurrentSubAreaIndex] = useState(0);
	const [continueButtonText, setContinueButtonText] = useState(debateData.subArea[1].title);
	const [currentSubArea, setCurrentSubArea] = useState(debateData.subArea[0]);
	const [currentTopVote, setCurrentTopVote] = useState(topVotes[0]);
	const [votedActions, setVotedActions] = useState(null);
	const [pointData, setPointData] = useState(null);

	let animationDelay = 0;

	// Replays all animations on page
	const replayAnimations = () => {
		document.getAnimations().forEach((anim) => {
			if (anim.animationName !== 'fadeIn-area' && anim.animationName !== 'fadeOut') {
				anim.cancel();
				anim.play();
			}
		});
	};

	useEffect(() => {
		const newVotedActions = game.votedActions.filter((votedAction) => {
			return votedAction.area === gameStepData.area && votedAction.category <= (currentSubAreaIndex + 1);
		});

		setVotedActions(newVotedActions);
		setCurrentTopVote(topVotes[currentSubAreaIndex]);
		setCurrentSubArea(debateData.subArea[currentSubAreaIndex]);
		setPointData(getNewAreaPoints(newVotedActions)[currentSubAreaIndex]);
	}, [
		game.votedActions,
		gameStepData.area,
		currentSubAreaIndex,
		debateData.subArea,
		topVotes,
	]);

	const handleContinueButton = () => {
		let newIndex = currentSubAreaIndex + 1;
		if (newIndex > debateData.subArea.length - 1) {
			handleGoToGameStep('next');
			return;
		}

		replayAnimations();

		newIndex >= debateData.subArea.length - 1 ?
			setContinueButtonText(continueButtonTexts.voteResult) :
			setContinueButtonText(debateData.subArea[newIndex + 1].title);

		setCurrentSubAreaIndex(newIndex);
	};

	return (
		<div className="VoteResult">
			{showPopup && <Popup
				title={generalUiTexts.theRules}
				message={rules}
				messageClass={'rules'}
				cancelButtonText={generalUiTexts.ok}
				onContinueButtonClick={() => {}}
				onCancelButtonClick={() => {setShowPopup(false);}}
				showContinue={false}
				textAlign='left'
			/>}
			
			{/* Area background svg */}
			<Area votedActions={votedActions} animated={true} position={'large-side'} areaId={debateData.id}/>

			{/* Header */}
			<div className='VoteResult-headerWrapper'>
				<div className='VoteResult-filler'/>
				<div className='VoteResult-titleWrapper'>
					<span>{pageUiTexts.result}</span>
				</div>
				<div className='VoteResult-rulesButton'>
					<Button
						text={generalUiTexts.rules}
						classes={['rules']}
						onClick={() => {setShowPopup(true);}}
					/>
				</div>
			</div>

			{/* Body */}
			<div className='VoteResult-bodyWrapper'>
				<div className='VoteResult-resultWrapper'>
					<div className='VoteResult-subAreas'>
						{allVotes.length === 0 ? '' : 
							// We need to wait til votes are loaded
							// This may not be the case on first render
							<div className='VoteResult-subArea'>
								<div className='VoteResult-subAreaTitleWrapper'>
									<div className='VoteResult-subAreaTitle'>
										<span>{voteTexts.votesFrom} {currentSubArea.title}</span>
									</div>
								</div>
								<div className='VoteResult-actions'>
									{currentSubArea.actions.map((action, index) => {
										// Returns if current action is top vote for subarea
										let topVote = currentTopVote.action === action.id;

										let actionClassName = 'VoteResult-action';
										let checkmarkClassName = 'VoteResult-checkmark';

										if (topVote) {
											actionClassName += ' topVote';
											checkmarkClassName += ' active';
										};
										const categoryVotes = allVotes.find((votes) => {
											return votes.category === currentSubArea.id;
										});

										const actionVotes = categoryVotes.votes.find((vote) => {
											return vote.action === action.id;
										});

										if (actionVotes) {
											animationDelay += 0.5;
										}

										return (
											<div key={index} className='VoteResult-actionWrapper'>
												{/* Sets animation delay to fit with after all votes */}
												<div className={actionClassName}
													style={{animationDelay: 
														(categoryVotes.votes.length / 2) + 0.5 + 's'}}>
													<div className='VoteResult-actionHeader'>
														<div className='VoteResult-title'>
															<span>
																{action.title}
															</span>
															{action.isExpensive ? 
																<div className='VoteResult-isExpensive'/> : ''}
														</div>
														{/* Adds a checkmark if current action is top vote */}
														{/* Sets animation delay to fit with after all votes */}
														<div className={checkmarkClassName}
															style={{animationDelay: 
																(categoryVotes.votes.length / 2) + 1 + 's'}}/>
													</div>
													<div className='VoteResult-actionVotes'>
														{/* 
															We check if there are any votes for the current action 
														*/}
														{actionVotes !== undefined && actionVotes !== null ? 
															actionVotes.groupIds.map((groupId, votesIndex) => {
																return (
																	<div 
																		key={votesIndex}
																		className={
																			'VoteResult-groupVote groupBackground'
																			+ groupId}
																		style={{animationDelay: animationDelay + 's'}}
																	>
																		<span>{actionVotes.points[votesIndex]}</span>
																	</div>
																);
															}) : ''
														}
													</div>
												</div>
											</div>
										);
									})}

									<div className='VoteResult-voteResultsPointsWrapper'>
										<div className='VoteResult-voteResultPointsTitle'>
											<span>{'Naturværdier for ' + currentTopVote.actionTitle}</span>
										</div>

										{pointData !== undefined && pointData !== null ? 
											pointData.points.map((point, index) => {
												const pointTypeName = pointTypes.find((pointType) => {
													return pointType.id === point.type;
												}).name;

												return (
													<div key={index} className={'VoteResult-voteResultsPoint'}>
														<div className='VoteResult-PointName'>
															<span>{pointTypeName}</span>
														</div>
														<div className='VoteResult-Point'>
															<span>{'+' + point.point}</span>
														</div>
													</div>
												);
											}) : ''}
									</div>
								</div>
							</div>
						}
					</div>
				</div>

				<div className='VoteResult-navigation'>
					<div className='VoteResult-navigationButtons'>
						<PdfLink link={teachersGuide} text={generalUiTexts.teachersGuide} styleClass='dark'/>
						<div>
							<Button
								text={generalUiTexts.lobby}
								classes={['lobby']}
								onClick={() => {handleGoToGame();}}
							/>
							{appConfig.env !== 'production' ? <Button
								text={generalUiTexts.back}
								onClick={() => {handleGoToGameStep('prev');}}
							/> : ''}
						</div>
					</div>
					
					<GameCode className='VoteResult-gameCode' gameCode={gameCode}/>
				
					<NextPageButton handleGoToGameStep={handleContinueButton} text={continueButtonText}/>
				</div>
			</div>
		</div>
	);
};

VoteResult.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	gameStepData: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	gameCode: PropTypes.string.isRequired,
	debateData: PropTypes.object.isRequired,
	topVotes: PropTypes.array.isRequired,
	allVotes: PropTypes.array.isRequired,
};

export default VoteResult;