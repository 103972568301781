import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import PropTypes from 'prop-types';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import apiHelper from 'helpers/api-helper';
import { errorUiTexts } from 'data/ui-texts';
import appConfig from 'config/app.config';
import LoginGroup from './login-group';


class LoginGroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: null,
			groups: null,
			feedback: null,
			isLoading: false,
			isCreatingGroup: false,
			invalidGameCode: false,
			groupId: null,
			groupName: null
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null,
			invalidGameCode: false
		});
	}


	/**
	 * Find the game with the given code, return error if not found
	 * @param {obj} event 
	 */	
	handleFindGame = (event) => {
		if (event) event.preventDefault();
		const code = this.state.code;
		if (!code || code.length === 0) {
			this.setState({feedback: errorUiTexts.missingFields});
			return;
		}
		this.setState({isLoading: true}, () => {
			const db = firebase.firestore();
			db.collection(appConfig.gamesDbName).doc(code).get().then((doc) => {
				/* Game exists */
				if (doc.exists) {
					/* Game started, get groups */
					db.collection(appConfig.groupsDbName).where('gameId', '==', code).get().then((docs) => {
						let groups = [];
						docs.forEach((doc) => {
							let data = doc.data();
							data.id = doc.id;
							groups.push(data);
						});
						this.setState({groups: groups, isLoading: false}, () => {
							this.props.setChoosingGroups(true);
						});
					}).catch((error) => {
						console.error(error);
						const msg = errorUiTexts.unknownError;
						this.setState({feedback: msg, isLoading: false});		
					});
					
				} else {
					/* Game does not exist */
					const msg = errorUiTexts.noGameCodeMatch;
					this.setState({feedback: msg, isLoading: false});
				}
			}).catch((error) => {
				console.error(error);
				const msg = errorUiTexts.unknownError;
				this.setState({feedback: msg, isLoading: false});
			});	
		});
	}	
	/**
	 * Create new group and get login token
	 * @param {object} event 
	 * @param {string} groupName 
	 * @returns 
	 */
	handleCreateGroup = (event, groupName) => {
		if (event) event.preventDefault();
		if (!groupName || groupName.length < appConfig.groupNameMinLength) {
			this.setState({feedback: errorUiTexts.groupNameTooShort});
			return;
		}
		if (groupName.length > appConfig.groupNameMaxLength) {
			this.setState({feedback: errorUiTexts.groupNameTooLong});
			return;
		}

		this.setState({isLoading: true}, () => {
			apiHelper('group/create-group', {gameCode: this.state.code, groupName: groupName})
				.then((response)=>{
					if (response.status === 'success' && response.token) {
						this.loginWithToken(response.token);
					} else {
						this.setState({isLoading: false, feedback: response.error});
					}
				})
				.catch((rejection) => {
					console.error(rejection);
					this.setState({
						isLoading: false, 
						feedback: errorUiTexts.unknownError
					});
				});
		});
	 };


	 /**
	 * Get a login token.
	 * Call firebase auth to sign in with that token.
	 * @param {number} groupId 
	 */
	handleGetLoginToken = (groupId) => {
		this.setState({
			isLoading: true,
			groupId: groupId
		});

		apiHelper('group/join-game', {gameCode: this.state.code, groupId: groupId}).then(
			(response)=>{
				if (response.status === 'success' && response.token) {
					this.loginWithToken(response.token);
				} else {
					console.error(response);
					const msg = errorUiTexts.unknownError;
					this.setState({
						feedback: msg,
						isLoading: false,
						groupId: null,
					});
				}
			},
			(rejection) => {
				this.setState({
					isLoading: false,
					groups: null,
					groupId: null
				});
				console.error('rejection: ', rejection);
			}
		);
	}

	/**
	 * Login with token
	 * @param {string} token 
	 */
	loginWithToken = (token) => {
		firebase.auth().signInWithCustomToken(token)
			.then(() => {
				// this.setState({
				// 	isLoading: false,
				// 	groupId: null
				// });
			// All okay, should trigger the authStateChanged in LoginController
			})
			.catch((error) => {
				console.error('sign in error', error);
				const msg = errorUiTexts.unknownError;
				this.setState({
					feedback: msg,
					isLoading: false,
					groupId: null,
				});
			});
	}

	render = () => {
		return (
			<LoginGroup
				handleInput={this.handleInput}
				handleFindGame={this.handleFindGame}
				handleCreateGroup={this.handleCreateGroup}
				handleGetLoginToken={this.handleGetLoginToken}
				code={this.state.code}
				groups={this.state.groups}
				feedback={this.state.feedback}
				isLoading={this.state.isLoading}
				groupId={this.state.groupId}
				invalidGameCode={this.state.invalidGameCode}
			/>
		);
	}
}

LoginGroupController.propTypes = {
	setChoosingGroups: PropTypes.func.isRequired,
};

export default LoginGroupController;
