import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import appConfig from 'config/app.config';
import GameController from 'components/game/game-controller';
import Loading from 'components/loading/loading';
import 'firebase/compat/firestore';


class GroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			group: null,
			game: null,
		};
		this.unsubscribeGame = null;
		this.unsubscribeGroup = null;
	}

	componentDidMount = () => {
		this.subscribeToGroup().then((groupResolve) => {
			if (groupResolve.status === 'success') {
				this.subscribeToGame(groupResolve.group.gameId).then((gameResolve) => {
					if (gameResolve.status === 'success') {
						this.setState({isLoading: false});
					}
				}).catch((reject) => {
					console.error(reject);
				});
			}
		}).catch((reject) => {
			console.error(reject);
		});
		// Get group with userID
		// Get game with gameId from group
	}

	subscribeToGame = (gameCode) => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		return new Promise((resolve, reject) => {
			const db = firebase.firestore();
			this.unsubscribeGroup = db.collection(appConfig.gamesDbName).doc(gameCode)
				.onSnapshot((game) => {
					if (game.exists) {
						this.setState({game: game.data()});
						resolve({status: 'success'});
					} else {
						reject('No game with code ' + gameCode + ' exists.');
					}
				});
		});
	}
	
	/**
	 * Update group
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGroup = (updates) => {
		let groupId = this.props.userId;
		let db = firebase.firestore();
		let groupRef = db.collection(appConfig.groupsDbName).doc(groupId);
		return groupRef.update(updates);
	};

	subscribeToGroup = () => {
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();
		
		return new Promise((resolve, reject) => {
			const db = firebase.firestore();
			this.unsubscribeGroup = db.collection(appConfig.groupsDbName).doc(this.props.userId).onSnapshot((doc) => {
				/* Group exists */
				if (doc.exists) {
					this.setState({group: doc.data()});
					resolve({status: 'success', group: doc.data()});		
				} else {
					reject('No group called ' + this.props.userId + ' exists.');
				}
			});
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from games */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();
	}

	 /**
	 * Render component
	 */
	 render() {
		if (this.state.isLoading) {
			return (
				<Loading 
					loadErrMsg={this.state.loadErrMsg}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};
		return (
			<>
				<GameController
					isFacilitator={false}
					game={this.state.game}
					handleLogout={this.props.handleLogout}
					updateGroup={this.updateGroup}
					group={this.state.group}
				/>
			</>
		);
	 }
}

GroupController.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
};

export default GroupController;
