const imagesData = [
	// IMAGES
	'area.svg',
	'area2.svg',
	'area3.svg',
	'cloud.svg',
	'frontpage-area.svg',
	'frontpage-logo.svg',
	'full-area.svg',
	'icon-loading.svg',
	'icon-logout.svg',
	// ICONS
	'icons/arrow-down.svg',
	'icons/arrow-up.svg',
	'icons/checkmark.svg',
	'icons/continue-arrow.svg',
	'icons/cross.svg',
	'icons/delete.svg',
	'icons/expensive.svg',
	'icons/eye-button.svg',
	'icons/eye.svg',
	'icons/group-1-logo.svg',
	'icons/group-2-logo.svg',
	'icons/group-3-logo.svg',
	'icons/group-4-logo.svg',
	'icons/group-5-logo.svg',
	'icons/group-6-logo.svg',
	'icons/lobby.svg',
	'icons/logout.svg',
	'icons/rules.svg',
	'icons/star-bronze.svg',
	'icons/star-gold.svg',
	'icons/star-silver.svg',
	'icons/vote-indicator.svg',
];

export default imagesData;