import React, { Component } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import PropTypes from 'prop-types';
import ResetPassword from './reset-password';
import { errorUiTexts } from 'data/ui-texts';

class ResetPasswordController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			passwordIsReset: false,
			email: null,
			invalidEmail: false,
			feedback: null
		};
	}

	/**
	 *  Update input fields 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		this.setState({
			email: value,
			feedback: null,
			invalidEmail: false
		});
	}

	/** 
	 * Handle reset password request 
	 * @param {obj} event 
	 */
	handleSubmit = (event) => {
		if (event) event.preventDefault();
		if (!this.state.email) {
			let errorMsg = errorUiTexts.invalidEmail;
			this.setState({
				feedback: errorMsg,
				invalidEmail: true
			});
			return;
		}
		this.resetPasswordReset(this.state.email);
	}

	/** 
	 * Reset password 
	 */
	resetPasswordReset = (email) => {
		this.setState({isLoading: true});
		const auth = firebase.auth();
		auth.sendPasswordResetEmail(email)
			.then(()=>{
				this.setState({isLoading: false, passwordIsReset: true});
			})
			.catch((error) =>{
				console.log(error.code);
				let errorMsg = errorUiTexts.unknownError;
				if (error.code === 'auth/invalid-email') errorMsg = errorUiTexts.invalidEmail;
				if (error.code === 'auth/user-not-found') errorMsg = errorUiTexts.emailNotFound;
				console.log(errorMsg);
				this.setState({
					feedback: errorMsg,
					isLoading: false,
					invalidEmail: true
				});
			});
	}
	
	/**
	 * Render reset password component
	 */
	render() {
		return (
			<ResetPassword
				isLoading={this.state.isLoading}	
				passwordIsReset={this.state.passwordIsReset}
				email={this.state.email}
				feedback={this.state.feedback}
				handleInput={this.handleInput}
				handleSubmit={this.handleSubmit}
				setFacilitatorBoxType={this.props.setFacilitatorBoxType}
				invalidEmail={this.state.invalidEmail}
			/>
		);
	}
}

ResetPasswordController.propTypes = {
	setFacilitatorBoxType: PropTypes.func.isRequired
};

export default ResetPasswordController;


