import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './area-intro.scss';
import Button from 'components/ui/button/button';
import { pageUiTexts, pageTitles, continueButtonTexts, generalUiTexts } from 'data/ui-texts';
import GameCode from 'components/ui/game-code/game-code';
import Area from 'components/ui/area/area';
import Popup from 'components/ui/popup/popup';
import { rules, roundIntro } from 'data/debate-data';
import NextPageButton from 'components/ui/next-page-button/next-page-button';
import { getDebateData, getRoundNumber } from 'helpers/area-data-helper';
import appConfig from 'config/app.config';
import PdfLink from 'components/ui/pdf-link/pdf-link';
import teachersGuide from 'assets/pdfs/lærervejledning.pdf';

const AreaIntro = ({handleGoToGame, handleGoToGameStep, gameCode, gameStepData}) => {
	const [showPopup, setShowPopup] = useState(false);
	const [activeSubareaIndex, setActiveSubareaIndex] = useState(null);
	const debateData = getDebateData(gameStepData.area);
	const roundNumber = getRoundNumber(gameStepData.area);
	let roundIntroText = '';

	if (roundNumber === 1) {
		roundIntroText = pageUiTexts.roundIntro;
	} else if (roundNumber === 2) {
		roundIntroText = pageUiTexts.roundIntro2;
	} else {
		roundIntroText = pageUiTexts.roundIntro3;
	}

	const setActiveSubarea = (index) => {
		if (activeSubareaIndex === index) {
			setActiveSubareaIndex(null);
		} else {
			setActiveSubareaIndex(index);
		}
	};

	return (
		<div className="AreaIntro">
			{showPopup && <Popup
				title={generalUiTexts.theRules}
				message={rules}
				messageClass={'rules'}
				cancelButtonText={generalUiTexts.ok}
				onContinueButtonClick={() => {}}
				onCancelButtonClick={() => {setShowPopup(false);}}
				showContinue={false}
				textAlign='left'
			/>}
			{/* Area background svg */}
			<Area areaId={gameStepData.area}/>

			{/* Body */}
			<div className='AreaIntro-bodyWrapper'>
				<div className='AreaIntro-header'>
					<div className='AreaIntro-areaInfoWrapper'>
						<div className='AreaIntro-areaInfo'>
							<div className='AreaIntro-areaInfoName'>
								<span>{debateData.title}</span>
							</div>
							<div className='AreaIntro-areaInfoDescription'>
								<span>{debateData.disposition}</span>
								<p>{roundIntroText}</p>
							</div>
						</div>

						<div className='AreaIntro-tabs'>
							{roundNumber === 1 ? roundIntro.map((tab, index) => {
								let active = '';

								if (index === activeSubareaIndex) {
									active = ' active';
								}

								return (
									<div key={index} className='AreaIntro-tabWrapper'>
										<div className={'AreaIntro-tab' + active}
											onClick={() => {setActiveSubarea(index);}}>
											<span>{index + 1 + '. ' + tab.title}</span>
										</div>
										<div className={'AreaIntro-arrow' + active}/>
										<div className={'AreaIntro-tabDescription' + active}>
											<div className='AreaIntro-closeButton'
												onClick={() => {setActiveSubarea(null);}}/>
											<div className='AreaIntro-tabDescriptionText'
												dangerouslySetInnerHTML={{__html: tab.description}} />
										</div>
									</div>
								);
							}) : ''}
						</div>
					</div>
					
					<div className='AreaIntro-title'>
						<span>{pageTitles.round + ' ' + roundNumber}</span>
					</div>

					<div className='AreaIntro-rulesButton'>
						<Button
							text={generalUiTexts.rules}
							classes={['rules']}
							onClick={() => {setShowPopup(true);}}
						/>
					</div>
				</div>
				<div className='AreaIntro-navigation'>
					<div className='AreaIntro-navigationButtons'>
						<PdfLink link={teachersGuide} text={generalUiTexts.teachersGuide} styleClass='dark'/>
						<div>
							<Button
								text={generalUiTexts.lobby}
								classes={['lobby']}
								onClick={() => {handleGoToGame();}}
							/>
							{appConfig.env !== 'production' ? <Button
								text={generalUiTexts.back}
								onClick={() => {handleGoToGameStep('prev');}}
							/> : ''}
						</div>
					</div>
					
					<GameCode className='AreaIntro-gameCode' gameCode={gameCode}/>
				
					<NextPageButton handleGoToGameStep={handleGoToGameStep} text={continueButtonTexts.areaIntro}/>
				</div>
			</div>
		</div>
	);
};

AreaIntro.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	gameCode: PropTypes.string.isRequired,
	gameStepData: PropTypes.object.isRequired,
};

export default AreaIntro;