import React, {useState} from 'react';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';

// ask diana
// import Background from 'components/background/background';

import LoginFacilitatorController from './login-facilitator-controller';
import RequestPasswordController from './request-password-controller';
import CreateUserController from './create-user-controller';
import LoginGroupController from './login-group-controller';
import './login.scss';
import Area from 'components/ui/area/area';

const Login = () => {
	/* Get active box component for facilitator box */
	let [facilitatorBoxType, setFacilitatorBoxType] = useState('login');

	let [choosingGroup, setChoosingGroup] = useState(false);

	let FacilitatorBoxComponent = LoginFacilitatorController;
	if (facilitatorBoxType === 'createUser') FacilitatorBoxComponent = CreateUserController;
	if (facilitatorBoxType === 'resetPassword') FacilitatorBoxComponent = RequestPasswordController;

	return (
		<div className="Login">
			{/* Area background svg */}
			<Area position='bottom' areaId={'area1'}/>

			<div className="Login-wrap">
				<div className="Login-box">
					{!choosingGroup ?
						<FacilitatorBoxComponent setFacilitatorBoxType={setFacilitatorBoxType} /> : ''
					}
					<LoginGroupController setChoosingGroups={setChoosingGroup}/>
				</div>
			</div>

			<CookieConsentController />
		</div>
	);
};

export default Login;