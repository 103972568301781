import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './area-score.scss';
import { pageTitles, continueButtonTexts, generalUiTexts } from 'data/ui-texts';
import NextPageButton from 'components/ui/next-page-button/next-page-button';
import { getRoundNumber, getAreaMaxPoints } from 'helpers/area-data-helper';
import GameCode from 'components/ui/game-code/game-code';
import Button from 'components/ui/button/button';
import Area from 'components/ui/area/area';
import { pointTypes } from 'data/debate-data';
import appConfig from 'config/app.config';
import PdfLink from 'components/ui/pdf-link/pdf-link';
import teachersGuide from 'assets/pdfs/lærervejledning.pdf';

const AreaScore = ({handleGoToGame, handleGoToGameStep, gameStepData, gameCode, game}) => {
	// Getting voted actions for area background
	let actions = [];
	if (game.votedActions) {
		actions = game.votedActions.filter((action) => {return action.area === gameStepData.area;});
	}
	
	const roundNumber = getRoundNumber(gameStepData.area);
	const [pointData, setPointData] = useState([]);
	const [totalPoint, setTotalPoint] = useState(0);
	
	useEffect(() => {
		const pointTypeTotals = getAreaMaxPoints(null);
		const newTotalPoint = Math.max(...pointTypeTotals.map((type) => {return type.point;}));

		const areaPoints = game.areaPoints;
	
		const newData = pointTypes.map((type) => {
			const areaPoint = areaPoints.filter((areaPoint) => {return areaPoint.type === type.id;});

			const pointSegments = [];
			areaPoint.forEach((point) => {
				for (var i = 0; i < point.total; i++) {
					pointSegments.push(point.area);
				}
			});

			return {name: type.name, areaPoint: pointSegments};
		});

		newData.sort((a, b) => {
			if (a.name >= b.name) return 1;
			
			return -1;
		});

		setTotalPoint(newTotalPoint);
		setPointData(newData);
	}, [game.areaPoints, gameStepData.area]);

	return (
		<div className="AreaScore">
			{/* Area background svg */}
			<Area votedActions={actions} areaId={gameStepData.area}/>

			<div className='AreaScore-title'>
				<span>{pageTitles.areaScoreTitle}</span>
			</div>

			<div className='AreaScore-scoreboard'>
				<div className='AreaScore-scoreboardPoints'>
					{pointData.map((data, pointIndex) => {
						return (
							<div key={pointIndex} className='AreaScore-scoreboardWrapper'>
								<span>{data.name}</span>
								<div className='AreaScore-scoreboardBar'>
									{data.areaPoint.map((areaPoint, index) => {
										// The delay should reflect the animations we actually show
										const animationDelay = (index * 0.15 + 0.5) - ((roundNumber - 1) * 0.15);
										
										// We dont want to animate previous rounds
										let animated = true;
										switch (areaPoint) {
										case 'area1':
											animated = roundNumber === 1; 
											break;
											
										case 'area2':
											animated = roundNumber < 3; 
											break;
											
										case 'area3':
											animated = true; 
											break;

										default: 
											animated = false; 
											break;
										}

										return (
											<div key={index} className={'AreaScore-barSegmentWrapper'}
												style={{width: (100 / totalPoint) + '%'}}
											>
												<div 
													className={
														'AreaScore-barSegment ' 
														+ areaPoint 
														+ (animated ? ' animated' : '')
													}
													style={{animationDelay: animationDelay + 's'}}
												/>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Contains gamecode and navigation buttons */}
			<div className='AreaScore-navigation'>
				<div className='AreaScore-navigationButtons'>
					<PdfLink link={teachersGuide} text={generalUiTexts.teachersGuide} styleClass='dark'/>
					<div>
						<Button
							text={generalUiTexts.lobby}
							classes={['lobby']}
							onClick={() => {handleGoToGame();}}
						/>
						{appConfig.env !== 'production' ? <Button
							text={generalUiTexts.back}
							onClick={() => {handleGoToGameStep('prev');}}
						/> : ''}
					</div>
				</div>

				<GameCode className='AreaScore-gameCode' gameCode={gameCode}/>

				<NextPageButton handleGoToGameStep={handleGoToGameStep}
					text={(roundNumber + 1) < 4 ? 
						continueButtonTexts.scoreBoard + ' ' + (roundNumber + 1) : 
						continueButtonTexts.finalResult}/>
			</div>
		</div>
	);
};

AreaScore.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	gameStepData: PropTypes.object.isRequired,
	gameCode: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
};

export default AreaScore;