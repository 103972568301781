import React, { useState} from 'react';
import PropTypes from 'prop-types';
import './group-sidebar.scss';
import { groupData } from 'data/group-data';
import GroupInfo from '../group-info/group-info';
import GroupGoals from '../group-goals/group-goals';

const GroupSidebar = ({group, game, showGroups = true}) => {
	// Used to expand and contract group info for other groups
	const [expandedGroupId, setExpandedGroupId] = useState(0);
	const expandGroup = (id) => {
		if (id === expandedGroupId) {
			setExpandedGroupId(0);
		} else {
			setExpandedGroupId(id);
		}
	};

	return (
		<div className='GroupSidebar'>
			{/* Group Information Sidebar */}
			<div className='GroupSidebar-groupInfoBar'>
				<GroupInfo closeable={false} group={group} game={game}/>

				{showGroups ? groupData.map((g, index) => {
					var isCurrentGroup = g.id === group.index;

					// current group is shown before this list, therefore we skip it
					if (isCurrentGroup) {
						return (
							''
						);
					}

					return (
						<div
							className={'GroupSidebar-otherGroupInfo castShadow groupBackground' + g.id}
							key={'groupInfo' + index}
						>
							<div className='GroupSidebar-otherGroupTitleWrapper'>
								<div className='GroupSidebar-otherGroupTitle'>{g.name}</div>
								<div 
									className={expandedGroupId === g.id ? 
										'GroupSidebar-closeIcon' : 'GroupSidebar-expandIconDown'}
									onClick={() => {expandGroup(g.id);}}
								/>
							</div>
							<div className={g.id === expandedGroupId ? 
								'GroupSidebar-groupInfoExpandable expanded' : 'GroupSidebar-groupInfoExpandable' }
							>
								<GroupGoals group={g}/>
							</div>
						</div>
					);
				}) : ''}
			</div>
		</div>
	);
};

GroupSidebar.propTypes = {
	group: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	showGroups: PropTypes.bool,
};

export default GroupSidebar;