import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};
/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyABpzDplMcQTzcJvccvfSZfMBeDMXab9nA',
		authDomain: 'cgl-natursyn-test.firebaseapp.com',
		projectId: 'cgl-natursyn-test',
		storageBucket: 'cgl-natursyn-test.appspot.com',
		messagingSenderId: '974116428109',
		appId: '1:974116428109:web:73436f995d090628a23f04'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyAeEWsH-lMrBoe4w1lJSR4VM6W1NovDX2o',
		authDomain: 'cgl-natursyn-production.firebaseapp.com',
		projectId: 'cgl-natursyn-production',
		storageBucket: 'cgl-natursyn-production.appspot.com',
		messagingSenderId: '781741429606',
		appId: '1:781741429606:web:6bf31071df12b2cacb6ea7'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

/* Initialize analytics */
if (env === 'production') {
	firebase.analytics();
}

export default firebase.firestore;