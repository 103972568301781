import React from 'react';
import PropTypes from 'prop-types';
import './frontpage.scss';
import Button from 'components/ui/button/button';
import {ReactComponent as Background} from 'assets/images/frontpage-area.svg';
import {ReactComponent as Logo} from 'assets/images/frontpage-logo.svg';
import CloudEngine from 'components/cloud-engine/cloud-engine';
import { generalUiTexts, pageUiTexts } from 'data/ui-texts';

const FrontPage = ({startGame}) => {
	return (
		<div className="Frontpage">
			<CloudEngine />
			
			<div className='Frontpage-backgroundWrapper'>
				<Logo className='Frontpage-logo'/>
				<Background className='Frontpage-background'/>
			</div>
			
			<div className='Frontpage-logoCGLWrapper'>
				<div className='Frontpage-logoCGL' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
			</div>

			<Button
				classes={['startGame']} 
				text={'START'} 
				onClick={() => {startGame();}}
			/>

			<div className='Frontpage-sisLogo'/>

			<div className='Frontpage-logos'>
				<div className='Frontpage-logoTitle'>
					<span>{generalUiTexts.developedBy}</span>
				</div>
				<div className='Frontpage-logoNFF'/>
				<div className='Frontpage-logoFR'/>
				<div className='Frontpage-logoJF'/>
				<div className='Frontpage-logoLF'/>
				<div className='Frontpage-logoSFF'/>
				<div className='Frontpage-logoBDK'
					dangerouslySetInnerHTML={
						{__html: pageUiTexts.logoGroupBDK}
					}
				/>
				<div className='Frontpage-logoSkjoldungernesLand'/>
			</div>
		</div>
	);
};

FrontPage.propTypes = {
	startGame: PropTypes.func.isRequired,
};

export default FrontPage;