import React from 'react';
import PropTypes from 'prop-types';
import './group-box.scss';
import { pageUiTexts } from 'data/ui-texts';

const GroupBox = ({group, groupName, showReadyButton, fadeReadyButton = false, onClickMethod, clicked}) => {
	return (
		<div className="GroupBox">
			<div className='GroupBox-groupWrapper'>
				<div 
					className={'GroupBox-groupBox' 
						+ (onClickMethod ? ' clickable' : '') 
						+ (clicked && onClickMethod ? ' clicked' : '')}
					onClick={() => {
						if (onClickMethod) {onClickMethod();}
					}}>
					<div className={'GroupBox-groupLogo groupBackground' + group.index + ' groupLogo' + group.index}/>
					<div className='GroupBox-groupName'>
						<span>{groupName}</span>
					</div>
				</div>

				{showReadyButton ?
					<div className={'GroupBox-groupReady' + (group.isPlaying ? ' active' : '') + 
						(fadeReadyButton ? '' : ' fadeOut')}>
						<span>
							{group.isPlaying ? pageUiTexts.groupReady : pageUiTexts.groupNotReady}
						</span>
					</div> : ''
				}
			</div>
		</div>
	);
};

GroupBox.propTypes = {
	group: PropTypes.object.isRequired,
	groupName: PropTypes.string.isRequired,
	showReadyButton: PropTypes.bool.isRequired,
	fadeReadyButton: PropTypes.bool,
	onClickMethod: PropTypes.func,
	clicked: PropTypes.bool,
};

export default GroupBox;