import { groupData } from 'data/group-data';
import { pointScalas } from 'data/debate-data';

/**
 * Gets the given groups points for the given area
 * @param {object} game 
 * @param {object} group 
 * @param {string} area 
 * @returns 
 */
function getGroupAreaPoints(game, group, area) {
	const areaPoints = game.areaPoints.filter((areaPoint) => {
		return areaPoint.area === area;
	});
	const gamePointData = groupData.find((data) => {
		return data.id === group.index;
	}).pointScalas;

	const pointTypeTotals = [];
	let pointsTotal = 0;

	areaPoints.forEach((areaPoint) => {
		const typeIndex = gamePointData.findIndex((data) => {
			return data === areaPoint.type;
		});

		if (typeIndex !== -1) {
			const typeTotal = areaPoint.total * pointScalas[typeIndex];
			pointTypeTotals.push({type: areaPoint.type, total: typeTotal});
			pointsTotal += typeTotal;
		}
	});

	return {total: pointsTotal, typeTotals: pointTypeTotals};
}

/**
 * Gets total area and game points for all groups, and returns an ordered list by total game points
 * @param {object} game 
 * @param {string} area 
 * @returns {Array} ordered list of group points
 */
function getGroupsAreaPoints(game, area) {
	const result = [];

	groupData.forEach((group) => {
		const areaPoints = game.areaPoints.filter((areaPoint) => {
			return areaPoint.area === area;
		});

		const gamePointData = groupData.find((data) => {
			return data.id === group.id;
		}).pointScalas;
	
		let pointsTotal = 0;
	
		areaPoints.forEach((areaPoint) => {
			const typeIndex = gamePointData.findIndex((data) => {
				return data === areaPoint.type;
			});
	
			if (typeIndex !== -1) {
				const typeTotal = areaPoint.total * pointScalas[typeIndex];
				pointsTotal += typeTotal;
			}
		});

		const gameTotal = getGroupGameTotal(group.id, game);

		result.push({groupId: group.id, roundTotal: pointsTotal, gameTotal: gameTotal});
	});
	
	return result.sort((a, b) => {
		return b.gameTotal - a.gameTotal;
	});
}

/**
 * Gets total game points for group
 * @param {Number} groupId 
 * @param {object} game 
 * @returns {Number} total game points for group
 */
function getGroupGameTotal(groupId, game) {
	const gamePointData = groupData.find((data) => {
		return data.id === groupId;
	}).pointScalas;

	let pointsTotal = 0;

	if (game.areaPoints !== undefined) {
		game.areaPoints.forEach((areaPoint) => {
			const typeIndex = gamePointData.findIndex((data) => {
				return data === areaPoint.type;
			});

			if (typeIndex !== -1) {
				const typeTotal = areaPoint.total * pointScalas[typeIndex];
				pointsTotal += typeTotal;
			}
		});
	}

	return pointsTotal;
}

export {getGroupAreaPoints, getGroupsAreaPoints, getGroupGameTotal};