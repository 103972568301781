import React from 'react';
import PropTypes from 'prop-types';
import './area.scss';
import {ReactComponent as Area1} from 'assets/images/area.svg';
import {ReactComponent as Area2} from 'assets/images/area2.svg';
import {ReactComponent as Area3} from 'assets/images/area3.svg';

const Area = (
	{position = 'center', votedActions = [], animated = false, areaId}
) => {
	// Getting classes for area changes
	const getActionClasses = (actions) => {
		const classes = [];

		actions.forEach((action) => {
			classes.push(action.area + '-Category' + action.category + '-Action' + action.action);
		});

		return classes;
	};
	const actionClasses = votedActions !== null ? getActionClasses(votedActions) : [];
	let stringClasses = '';
	if (animated) {
		// Replacing all ',' with animated class
		stringClasses = String(actionClasses.flat()).replaceAll(',', '-animated ');
		// Last in action should also be animated
		stringClasses += '-animated';
	} else {
		stringClasses = String(actionClasses.flat()).replaceAll(',', ' ');
	}

	// Gathering classes to final Area class
	const finalClassName = 'Area-' + areaId + 'BackgroundSvg ' + stringClasses + ' ' + position;

	let AreaBackground = Area1;
	if (areaId === 'area2') AreaBackground = Area2;
	else if (areaId === 'area3') AreaBackground = Area3;

	return (
		<div className="Area">
			<div className={'Area-backgroundWrapper'}>
				{/* Area background svg */}
				<AreaBackground className={finalClassName}/>
			</div>
		</div>
	);
};

Area.propTypes = {
	position: PropTypes.string,
	votedActions: PropTypes.array,
	animated: PropTypes.bool,
	areaId: PropTypes.string.isRequired,
};

export default Area;