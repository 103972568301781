const gameStepNames = ['debat', 'afstemning', 'resultat'];

const gameStepsData = [
	{	// Game introduction
		id: 'gameIntro',
		facilitatorPage: 'gameIntro',
		studentPage: 'group'
	},
	
	// -------- Debate Round 1 -------- //
	{
		id: 'areaIntro1',
		facilitatorPage: 'areaIntro',
		studentPage: 'attention',
		area: 'area1'
	},
	{
		id: 'debateRound1',
		name: gameStepNames[0],
		facilitatorPage: 'debateInfo',
		studentPage: 'areaInfo',
		area: 'area1' // Each area consists of an info screen, debate, voting round, results, and area changes
	},
	{
		id: 'voteRound1',
		name: gameStepNames[1],
		facilitatorPage: 'voteOverview',
		studentPage: 'vote',
		area: 'area1'
	},
	{
		id: 'voteResultRound1',
		name: gameStepNames[2],
		facilitatorPage: 'voteResult', // Shows progress and naturepoints for the shown area
		studentPage: 'attention',
		area: 'area1'
	},
	{
		id: 'scoresRound1',
		name: gameStepNames[2],
		facilitatorPage: 'areaScore', // Shows each groups score
		studentPage: 'attention',
		area: 'area1'
	},
	
	// -------- Debate Round 2 -------- //
	{
		id: 'areaIntro2',
		facilitatorPage: 'areaIntro',
		studentPage: 'attention',
		area: 'area2'
	},
	{
		id: 'debateRound2',
		name: gameStepNames[0],
		facilitatorPage: 'debateInfo',
		studentPage: 'areaInfo',
		area: 'area2' // Each area consists of an info screen, debate, voting round, results, and area changes
	},
	{
		id: 'voteRound2',
		name: gameStepNames[1],
		facilitatorPage: 'voteOverview',
		studentPage: 'vote',
		area: 'area2'
	},
	{
		id: 'voteResultRound2',
		name: gameStepNames[2],
		facilitatorPage: 'voteResult', // Shows progress and naturepoints for the shown area
		studentPage: 'attention',
		area: 'area2'
	},
	{
		id: 'scoresRound2',
		name: gameStepNames[2],
		facilitatorPage: 'areaScore', // Shows each groups score
		studentPage: 'attention',
		area: 'area2'
	},
	
	// -------- Debate Round 3 -------- //
	{
		id: 'areaIntro3',
		facilitatorPage: 'areaIntro',
		studentPage: 'attention',
		area: 'area3'
	},
	{
		id: 'debateRound3',
		name: gameStepNames[0],
		facilitatorPage: 'debateInfo',
		studentPage: 'areaInfo',
		area: 'area3' // Each area consists of an info screen, debate, voting round, results, and area changes
	},
	{
		id: 'voteRound3',
		name: gameStepNames[1],
		facilitatorPage: 'voteOverview',
		studentPage: 'vote',
		area: 'area3'
	},
	{
		id: 'voteResultRound3',
		name: gameStepNames[2],
		facilitatorPage: 'voteResult', // Shows progress and naturepoints for the shown area
		studentPage: 'attention',
		area: 'area3'
	},
	{
		id: 'scoresRound3',
		name: gameStepNames[2],
		facilitatorPage: 'areaScore', // Shows the area's nature values
		studentPage: 'attention',
		area: 'area3'
	},
	{
		id: 'finalGroupScores',
		facilitatorPage: 'finalGroupScores', // Shows the total group scores for all groups
		studentPage: 'attention',
		area: 'area3'
	},
	{
		id: 'finalArea',
		facilitatorPage: 'finalArea', // Compares the area before and after the changes
		studentPage: 'attention',
		area: 'area3'
	}
];

module.exports = {gameStepsData, gameStepNames};