const pageUiTexts = {
	gameCode: 'Spilkode:',
	gameCodeNoColon: 'Spilkode',
	startTimer: 'Start timer',
	groupHint: 'Debat hints',
	primaryGoal: '1. prioritet',
	secondaryGoal: '2. prioritet',
	tertiaryGoal: '3. prioritet',
	whatShouldHappen: 'Hvad skal der ske med',
	result: 'Resultat',
	groupReady: 'klar',
	groupNotReady: 'ikke klar',
	attention: 'Følg med på tavlen',
	finalAreaScore: 'Samlet for alle områder',
	ourPoints: 'Vores nuværende point',
	presentations: 'Lad grupperne præsentere sig kort for hinanden.',
	roundIntro: 'Nedenfor er en beskrivelse af hvad der kommer til at ske i runden.',
	roundIntro2: 'Runden foregår ligesom runde 1.',
	roundIntro3: 'Runden foregår ligesom de foregående runder.',
	debateIntro: `Nedenfor kan du se de forskellige tiltag der er til debat.
				Der skal vedtages ét tiltag per underområde. 
				<br><br> Eleverne kan også se tiltagene på deres computere.`,
	debateIntro2: 'Nedenfor kan du se de forskellige tiltag der er til debat.',
	logoGroupBDK: `Bevar Danmarks<br>
				kulturlandskaber`,
	votingStarted: 'Afstemningen er nu igang!',
	howToVote: 'Træk jeres indflydelse op på de tiltag I vil stemme på.',
	gameCodeInfo: 'Når din lærer opretter et spilforløb, genereres en unik spilkode.',
	clickToPresent: '<b>Klik</b> på en gruppe for at se deres præsentation',
};

const generalUiTexts = {
	theRules: 'Reglerne',
	ok: 'ok',
	rules: 'Regler',
	lobby: 'Lobby',
	back: 'Tilbage',
	logout: 'Log ud',
	loading: 'Loading',
	popupTitle: 'Advarsel!',
	cancel: 'cancel',
	continue: 'Fortsæt',
	title: 'Lobby',
	actions: 'Tiltag',
	teachersGuide: 'Lærervejledning',
	developedBy: 'Udviklet af:',
	fundedBy: 'Støttet af:',
	showUsers: 'Se brugere',
	users: 'Brugere'
};

const loginUiTexts = {
	studentLogin: 'Elev login',
	teacherLogin: 'Lærer login',
	login: 'Log ind',
	email: 'Email',
	password:'Kodeord',
	passwordRepeat: 'gentag password',
	createNewUser:'Opret ny bruger',
	forgotPassword: 'Glemt dit password?',
	goToLoginBtn:'Tilbage til login',
	resetPassword: 'Indtast din e-mail for at nulstille dit password:',
	createUserBtn: 'Opret',
	reset:  'Nulstil',
	choose: 'Vælg',
	chooseGroup: 'Vælg en gruppe',
	createUserFeedback: 'Din bruger er blevet oprettet. Gå tilbage og log ind for at spille.',
	passwordResetFeedback: 'Du har modtaget et link til at nulstille dit password i din indbakke.',
	cookiesPopup: {
		text: `Vi bruger cookies til at forbedre din spiloplevelse.
				Ved at klikke på 'OK' eller logge ind accepterer du vores brug af cookies.`,	
		more: 'Læse mere',
		btn:  'Ok',
	},
	loginInstructions: `Gå til: <b>%URL%</b><br>Log ind med spilkode: <b>%gameCode%</b><br>Vælg gruppe`,
};

const errorUiTexts = {
	missingFields: 'Fejl: Husk at udfylde alle felter.',
	unknownError: 'Fejl: Ukendt fejl.',
	emailNotFound: 'Fejl: Der findes ingen bruger med denne email.',
	invalidEmail: 'Fejl: Din email er ikke gyldig.',
	invalidEmailPassword: 'Fejl: Check om din e-mail og password er korrekte.',
	noPasswordMatch: 'Password skal være ens i begge felter.',
	weakPassword: 'Dit password skal være på mindst 8 tegn.',
	noGameCodeMatch: 'Fejl: Ugyldig spilkode.',
	groupNameTooLong: 'Fejl: Gruppenavn må ikke være længere end 15 tegn.',
	groupNameTooShort: 'Fejl: Gruppenavn skal være på mindst 2 tegn.',
	duplicatePlayerEmail: 'Fejl: Du har allerede tilføjet en spiller med denne e-mail.',
	userDataNotFound: 'Error: User data not in database. Auto log out in 5 seconds.',
	couldNotGetUser: 'Error: %error%. Auto log out in 5 seconds.',
};

const continueButtonTexts = {
	studentLogin: 'Videre',
	continueLobby: 'Præsentationer',
	debate: 'Afstemning',
	voteOverview: 'afslut afstemningen',
	voteResult: 'Naturværdier',
	scoreBoard: 'Runde',
	areaIntro: 'Debat',
	finalResult: 'Stillingen',
	finalGroupResult: 'Endeligt område',
	finish: 'Afslut',

	continueRound: 'runde',
};

const pageTitles = {
	lobbyTitle: 'Lav grupper',
	groupPresentations: 'Præsentationer',
	voteOverviewTitle: 'Afstemning',
	debateInfoTitle: 'Debat',
	scoreboardTitle: 'Stillingen',
	finalAreaTitle: 'Før og efter',
	finalAreaSubTitle: 'Sådan har i udviklet landskabet.<br>Hvordan matcher det jeres eget natursyn?',
	areaScoreTitle: 'Naturværdier',
	finalAreaScoreTitle: 'Naturværdier',
	round: 'runde',
};

const voteTexts = {
	notAllGroups: 'Der er nogle grupper der ikke har stemt endnu! Er du sikker på at du vil fortsætte?',
	noGroup: 'Der er ikke nogen grupper som har stemt endnu! Er du sikker på at du vil fortsætte?',
	saving: 'Gemmer',
	saved: 'Gemt',
	yourInfluence: 'Jeres indflydelse',
	votesFrom: 'Stemmefordeling -',
};

const pointText = {
	groupPointsTitle: 'Point for området ',
	total: 'Total:',
	placement: 'Placering',
	point: 'Point',
	trophy: 'Pokal',
};

const settingsUiTexts = {
	createGame: 'Start nyt spil',
	play: 'Fortsæt spil',
	date: 'Dato: ',
	class: 'Klasse: ',
	materials: 'Materialer',
	downloadMaterials: 'Download Print Materialer',
	tablePrintout: 'Badges og bordskilte',
	return: 'Genoptag spil',
	startGame: 'Start Spil'
};

const popupsData = {
	voteOverviewCancel: 'Fortryd',
	createGame: {
		title: 'Nyt spil',
		gameTitleLabel: '',
		gameTitlePlaceholder: 'Klassenavn',
		gamePlayDateLabel: '',
		create: 'opret spil',
		cancel: 'fortryd',
	},
	deleteGame: {
		text: 'Denne handling vil slette dit igangværende spil, vil du fortsætte?',
		title: 'Start nyt spil',
		delete: 'Start nyt spil',
		cancel: 'Fortryd',
	}
};

module.exports = {
	continueButtonTexts,
	settingsUiTexts,
	generalUiTexts,
	loginUiTexts,
	errorUiTexts,
	pageUiTexts,
	popupsData,
	pageTitles,
	voteTexts,
	pointText,
};
