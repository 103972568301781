import React from 'react';
import PropTypes from 'prop-types';
import './final-group-scores.scss';
import Button from 'components/ui/button/button';
import { groupData } from 'data/group-data';
import { pointText, pageTitles, continueButtonTexts, generalUiTexts } from 'data/ui-texts';
import { getGroupsAreaPoints } from 'helpers/group-points-helper';
import GameCode from 'components/ui/game-code/game-code';
import NextPageButton from 'components/ui/next-page-button/next-page-button';
import { pointThresholds } from 'data/debate-data';
import appConfig from 'config/app.config';
import PdfLink from 'components/ui/pdf-link/pdf-link';
import teachersGuide from 'assets/pdfs/lærervejledning.pdf';
import Area from 'components/ui/area/area';

const FinalGroupScores = ({handleGoToGame, handleGoToGameStep, gameStepData, game, gameCode}) => {
	const pointTotals = getGroupsAreaPoints(game, gameStepData.area);
	
	// Getting voting information for each area
	const votedActions1 = game.votedActions.filter((votedAction) => {
		return votedAction.area === 'area1';
	});
	const votedActions2 = game.votedActions.filter((votedAction) => {
		return votedAction.area === 'area2';
	});
	const votedActions3 = game.votedActions.filter((votedAction) => {
		return votedAction.area === 'area3';
	});

	return (
		<div className="FinalGroupScores">
			{/* Header */}
			<div className='FinalGroupScores-header'>
				<div className='FinalGroupScores-title'>
					<span>{pageTitles.scoreboardTitle}</span>
				</div>
			</div>

			{/* Body */}
			<div className='FinalGroupScores-score'>
				<div className='FinalGroupScores-groupsWrapper'>
					<div className='FinalGroupScores-scoreboardHeader'>
						<div className='FinalGroupScores-scoreboardPlacement'><span>{pointText.placement}</span></div>
						<div className='FinalGroupScores-scoreboardPoint'><span>{pointText.point}</span></div>
						<div className='FinalGroupScores-scoreboardTrophy'><span>{pointText.trophy}</span></div>
					</div>
					<div className='FinalGroupScores-groupPointsWrapper'>
						{pointTotals.map((totals, index) => {
							const groupId = totals.groupId;
							const groupName = groupData.find((data) => {return data.id === groupId;}).name;
							const gameTotalClassName = 'FinalGroupScores-groupGameTotal groupBackground' + groupId;
							let trophyClassName = 'FinalGroupScores-groupTrophy insetShadow groupBackground' + groupId;

							if (totals.gameTotal >= pointThresholds[1].limit) {
								trophyClassName += ' ' + pointThresholds[1].level;
							} else if (totals.gameTotal >= pointThresholds[0].limit) {
								trophyClassName += ' ' + pointThresholds[0].level;
							}

							return (
								<div key={index} className='FinalGroupScores-groupPoints'>
									<div className='FinalGroupScores-groupPlacement insetShadow'>
										<span>{index + 1}</span>
									</div>
									<div className={'FinalGroupScores-groupName insetShadow groupBackground' + groupId}>
										<span>{groupName}</span>
									</div>
									<div className={gameTotalClassName + ' insetShadow'}>
										<span>{totals.gameTotal}</span>
									</div>
									<div className={trophyClassName}/>
								</div>
							);
						})}
					</div>
				</div>
			</div>

			<div className='FinalGroupScores-backgroundWrapper'>
				{/* <Background className='FinalGroupScores-background'/> */}
				<Area votedActions={votedActions1} areaId={'area1'} position='allAreas'/>
				<Area votedActions={votedActions2} areaId={'area2'} position='allAreas'/>
				<Area votedActions={votedActions3} areaId={'area3'} position='allAreas'/>
			</div>

			<div className='FinalGroupScores-navigation'>
				<div className='FinalGroupScores-navigationButtons'>
					<PdfLink link={teachersGuide} text={generalUiTexts.teachersGuide} styleClass='dark'/>
					<div>
						<Button
							text={generalUiTexts.lobby}
							classes={['lobby']}
							onClick={() => {handleGoToGame();}}
						/>
						{appConfig.env !== 'production' ? <Button
							text={generalUiTexts.back}
							onClick={() => {handleGoToGameStep('prev');}}
						/> : ''}
					</div>
				</div>
				
				<GameCode className='FinalGroupScores-gameCode' gameCode={gameCode}/>

				<NextPageButton handleGoToGameStep={handleGoToGameStep}
					text={continueButtonTexts.finalGroupResult}/>
			</div>
		</div>
	);
};

FinalGroupScores.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	gameStepData: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	gameCode: PropTypes.string.isRequired,
};

export default FinalGroupScores;