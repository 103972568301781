import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';

const ResetPassword = (props) => {
	let {
		isLoading,
		passwordIsReset,
		email,
		feedback,
		handleInput,
		handleSubmit,
		setFacilitatorBoxType,
		invalidEmail
	} = props;

	if (passwordIsReset) {
		return (
			<div className="LoginFacilitator">
				<div className="Login-info">{loginUiTexts.passwordResetFeedback}</div>
				<div 
					className="Login-resetPasswordBtn"
					onClick={()=>{setFacilitatorBoxType('login');}}
				>{loginUiTexts.goToLoginBtn}</div>
			</div>
		);
	}

	return (
		<div className="LoginFacilitator">
			<form className="Login-form" onSubmit={handleSubmit}>
				<div className="Login-header">
					<div className="Login-info">{loginUiTexts.resetPassword}</div>
					<div className='Login-inputWrapper'>
						<input
							className={invalidEmail ? ' invalid' : ''}
							name="email"
							type="text"
							placeholder={loginUiTexts.email}
							autoComplete="section-resetpasswrd email"
							value={email ? email : ''}
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>
				</div>
				<div className='Login-spacing'/>
				<Button
					isLoading={isLoading}
					classes={['login']}
					text={loginUiTexts.reset}
					onClick={handleSubmit}
				/>
				<p className="Login-errorMessage">{feedback}</p>
				<div 
					className="Login-userButton"
					onClick={()=>{setFacilitatorBoxType('login');}}
				>{loginUiTexts.goToLoginBtn}</div>
			</form>
		</div>
	);
};

ResetPassword.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	passwordIsReset: PropTypes.bool.isRequired,
	email: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired,
	invalidEmail: PropTypes.bool.isRequired
};

export default ResetPassword;