import { Component } from 'react';
import VoteResult from './vote-result';
import PropTypes from 'prop-types';
import { getDebateData } from 'helpers/area-data-helper';
import Loading from 'components/loading/loading';

class VoteResultController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			topVotes: [],
			allVotes: [],
			loading: true,
		};
	}

	componentDidMount = () => {
		this.setTopVotes();
	}

	/**
	 * Sort and prepare vote data when component updates, as database data can be pending at mount
	 * @param {Object} previous old props, used to compare with new props 
	 */
	componentDidUpdate = (previous) => {
		if (previous.groups !== this.props.groups) {
			this.setTopVotes();
		}
	}

	setTopVotes = () => {
		const allSortedVotes = this.getSortedVotes();
		let topVotes = [];
		let previous;
		let previousAlternative;
		allSortedVotes.forEach((sortedVote) => {
			let current = {
				category: 		sortedVote.category,
				categoryTitle:	sortedVote.categoryTitle,
				action: 		sortedVote.votes[0].action,
				actionTitle:	sortedVote.votes[0].actionTitle,
				point: 			sortedVote.votes[0].point, 
				isExpensive: 	sortedVote.votes[0].isExpensive,
				groups: 		sortedVote.votes[0].groupIds,
				description: 	sortedVote.votes[0].description,
				value: 			sortedVote.votes[0].value,
				actionCount: 	sortedVote.votes.length,
				otherVotes: 	sortedVote.votes.slice(1),
			};

			let currentAlternative = null;
			if (current.otherVotes.length > 0) {
				currentAlternative = {
					category: 		sortedVote.category,
					categoryTitle:	sortedVote.categoryTitle,
					action: 		current.otherVotes[0].action,
					actionTitle:	current.otherVotes[0].actionTitle,
					point: 			current.otherVotes[0].point, 
					isExpensive: 	current.otherVotes[0].isExpensive,
					groups: 		current.otherVotes[0].groupIds,
					description: 	current.otherVotes[0].description,
					value: 			current.otherVotes[0].value,
					actionCount: 	current.otherVotes.length,
					otherVotes: 	current.otherVotes.slice(1),
				};
			}

			if (current.isExpensive && 
				previous !== undefined && 
				previous.isExpensive
			) {
				// Both categories have other actions to pick from
				// We compare and pick the most popular
				// The less popular is switched for its second most popular vote
				// If both are equally popular, tie breakers are based on the one with the most groups
				if (current.otherVotes.length !== 0 && previous.otherVotes.length !== 0) {
					if (current.point > previous.point) {
						topVotes[topVotes.length - 1] = previousAlternative;

					} else if (current.point < previous.point) {
						current = currentAlternative;

					} else if (current.point === previous.point) {
						if (current.groups.length > previous.groups.length) {
							topVotes[topVotes.length - 1] = previousAlternative;
						} else if (current.otherVotes.length !== 0) {
							// Switch current unless there are no other alternatives for current
							current = currentAlternative;
						}
					}
				
				// Current category only has one action with votes
				// Previous have multiple actions to pick from
				// Previous vote is switched for its second most popular vote
				} else if (
					current.otherVotes.length === 0 && 
					previous !== undefined && 
					previous.otherVotes.length !== 0
				) {
					topVotes[topVotes.length - 1] = previousAlternative;

				// Current category have multiple actions to pick from
				// Previous only has one action with votes
				// Current vote is switched for its second most popular vote
				} else if (
					current.otherVotes.length !== 0 && 
					previous !== undefined  && 
					previous.otherVotes.length === 0
				) {
					current = currentAlternative;
				}
			}

			topVotes.push(current);
			previous = current;
			previousAlternative = currentAlternative;
		});

		topVotes = topVotes.sort((a, b) => {
			return a.category - b.category;
		});

		this.setState({topVotes: topVotes, allVotes: allSortedVotes}, () => {
			this.savePointTotal(topVotes);
			this.saveTopVotes(topVotes);
		});
	}

	/**
	 * Checks and adds default votes if no votes have been made at all or no votes have been made for a subarea
	 * @param {Array} votes
	 * @returns {Array} array of votes
	 */
	addDefaultVotesIfNecessary = (votes) => {
		let debateData = getDebateData(this.props.gameStepData.area);
		
		// If there are votes missing for subarea, then we fill with default votes
		if (votes.length < debateData.subArea.length) {
			debateData.subArea.forEach((subArea) => {
				const subAreaHasVotes = votes.findIndex((vote) => {return vote.category === subArea.id;}) !== -1;

				if (!subAreaHasVotes) {
					votes.push({
						category: subArea.id,
						categoryTitle: subArea.title,
						votes: [{
							point: 			1, 
							action: 		subArea.actions[0].id,
							actionTitle:	subArea.actions[0].title,
							points:			[1],
							groupIds: 		[], 
							isExpensive: 	subArea.actions[0].isExpensive,
							description: 	subArea.actions[0].description,
							value: 			subArea.actions[0].value,
						}]
					});
				}
			});
		}
				
		return votes;
	}

	/**
	 * Updates firebase with voted through actions for area.
	 * @param {Array} topVotes 
	 */
	saveTopVotes = (topVotes) => {
		let votedActions = this.props.game.votedActions === undefined ? [] : this.props.game.votedActions;
		// if point total for area already exists we dont want to add on top of it
		if (votedActions.findIndex((action) => {return action.area === this.props.gameStepData.area;}) !== -1) {
			this.setState({loading: false});
			return;
		}

		topVotes.forEach((topVote) => {
			const votedAction = {
				area: this.props.gameStepData.area,
				category: topVote.category,
				action: topVote.action
			};
			votedActions.push(votedAction);
		});

		this.props.updateGame({votedActions: votedActions}).then(() => {
			this.setState({loading: false});
		});
	}

	/**
	 * Saves point total for area, based on top votes.
	 * @param {Array} topVotes 
	 */
	savePointTotal = (topVotes) => {
		let areaPoints = this.props.game.areaPoints === undefined ? [] : this.props.game.areaPoints;
		// if point total for area already exists we dont want to add on top of it
		if (areaPoints.findIndex((areaPoint) => {return areaPoint.area === this.props.gameStepData.area;}) !== -1) {
			return;
		}

		topVotes.forEach((topVote) => {
			topVote.value.forEach((value) => {
				const index = areaPoints.findIndex((point) => {
					return point.type === value.type && point.area === this.props.gameStepData.area;
				});

				if (index !== -1) {
					areaPoints[index].total += value.point;
				} else {
					areaPoints.push({
						type: value.type,
						total: value.point,
						area: this.props.gameStepData.area
					});
				}
			});
		});

		this.props.updateGame({areaPoints: areaPoints});
	}

	/**
	 * Combines votes for categories and sorts based on points within each category.
	 * @returns Sorted voting data according to category and points
	 */
	getSortedVotes = () => {
		let debateData = getDebateData(this.props.gameStepData.area);
		let allCategoryVotes = [];

		this.props.groups.forEach((group) => {
			if (group.votes === undefined) {
				// This group did not vote at all
				return;
			}

			const groupAreaVotes = group.votes.filter((vote) => {return vote.area === this.props.gameStepData.area;});
			if (groupAreaVotes === undefined) {
				// this group did not vote in the current area
				return;
			}

			groupAreaVotes.forEach((vote) => {
				const categoryIndex = allCategoryVotes.findIndex((allVote) => {
					return allVote.category === vote.category;
				});

				let actionIndex = -1;
				if (categoryIndex !== -1) { 
					// Category found, we check for whether action exists
					actionIndex = allCategoryVotes[categoryIndex].votes.findIndex((allVote) => {
						return allVote.action === vote.action; 
					});

					if (actionIndex !== -1) { 
						// Action found, we add points and group to it
						allCategoryVotes[categoryIndex].votes[actionIndex].point += vote.point;
						allCategoryVotes[categoryIndex].votes[actionIndex].groupIds.push(group.index);
						allCategoryVotes[categoryIndex].votes[actionIndex].points.push(vote.point);
					} else { 
						// Action does not exist, we add it to the category
						const area = debateData.subArea.find((subArea) => {
							return subArea.id === vote.category;
						});

						const actionData = area.actions.find((data) => {
							return data.id === vote.action; 
						});

						allCategoryVotes[categoryIndex].votes.push({
							point: 			vote.point, 
							action: 		vote.action,
							actionTitle:	actionData.title,
							points:			[vote.point],
							groupIds: 		[group.index], 
							isExpensive: 	actionData.isExpensive,
							description: 	actionData.description,
							value: 			actionData.value,
						});
					}
				} else { 
					// Category does not exist, we create it
					const area = debateData.subArea.find((subArea) => {
						return subArea.id === vote.category;
					});

					const actionData = area.actions.find((data) => {
						return data.id === vote.action; 
					});
					allCategoryVotes.push({
						category: vote.category,
						categoryTitle: area.title,
						votes: [{
							point: 			vote.point, 
							action: 		vote.action,
							actionTitle:	actionData.title,
							points:			[vote.point],
							groupIds: 		[group.index], 
							isExpensive: 	actionData.isExpensive,
							description: 	actionData.description,
							value: 			actionData.value,
						}]
					});
				}
			});
		});

		allCategoryVotes.forEach((category) => {
			category.votes = category.votes.sort((a, b) => {
				return b.point - a.point || b.groupIds.length - a.groupIds.length || a.action - b.action;
			});
		});

		return this.addDefaultVotesIfNecessary(allCategoryVotes);
	}

	render = () => {
		// Data is still loading, we wait
		if (this.state.loading) return (<Loading handleLogout={this.props.handleLogout}/>);
		
		let debateData = getDebateData(this.props.gameStepData.area);
		
		return (
			<VoteResult
				handleGoToGameStep={this.props.handleGoToGameStep}
				handleGoToGame={this.props.handleGoToGame}
				gameCode={this.props.gameCode}
				gameStepData={this.props.gameStepData}
				debateData={debateData}
				topVotes={this.state.topVotes}
				allVotes={this.state.allVotes}
				game={this.props.game}
			/>
		);
	}
}

VoteResultController.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	gameStepData: PropTypes.object.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateGame: PropTypes.func.isRequired,
	gameCode: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	game: PropTypes.object.isRequired,
};

export default VoteResultController;