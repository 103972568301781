import React, { useState} from 'react';
import PropTypes from 'prop-types';
import './group-goals.scss';
import { pageUiTexts } from 'data/ui-texts';
import { pointTypes } from 'data/debate-data';
import {ReactComponent as EyeIcon} from 'assets/images/icons/eye-button.svg';
import {ReactComponent as Cross} from 'assets/images/icons/cross.svg';

const GroupGoals = ({group}) => {
	// Used to expand and contract goal info
	const [goalInfo, setGoalInfo] = useState(null);

	const toggleGoalInfo = (id, name, description) => {
		if (goalInfo !== null && id === goalInfo.id) {
			setGoalInfo(null);
		} else {
			setGoalInfo({
				id: id,
				name: name,
				description: description
			});
		}
	};

	return (
		<div className='GroupGoals'>
			{group.pointScalas.map((goal, index) => {
				let groupGoalData = pointTypes.find((pointType) => {
					return pointType.id === goal;
				});

				let goalTitle = pageUiTexts.primaryGoal;
				if (index === 1) goalTitle = pageUiTexts.secondaryGoal;
				else if (index === 2) goalTitle = pageUiTexts.tertiaryGoal;

				return (
					<div key={'goal' + index} className='GroupGoals-groupGoals'>
						<div className='GroupGoals-groupGoalwrapper'> 
							<div className={'GroupGoals-groupGoalIcon group' + group.id}>
								<EyeIcon className='GroupGoals-eyeIcon' onClick={() => {
									toggleGoalInfo(index, groupGoalData.name, groupGoalData.description);
								}}/>
							</div>
							<div className={'GroupGoals-groupGoal groupColor' + group.id}>
								<div className='GroupGoals-groupGoalTitle'>
									{goalTitle}
								</div>
								<div className='GroupGoals-groupGoalName'>
									{groupGoalData.name}
								</div>
							</div>
						</div>
					</div>
				);
			})}

			{goalInfo ? <div className={'GroupGoals-goalInformation groupColor' + group.id}>
				<Cross
					className={'GroupGoals-closeIcon group' + group.id}
					onClick={() => {
						setGoalInfo(null);
					}}
				/>
				<div className='GroupGoals-goalName'><span>{goalInfo.name}</span></div>
				<div className='GroupGoals-goalInfoDescription'>
					<div className='GroupGoals-goalInfoText' dangerouslySetInnerHTML={{__html:goalInfo.description}}/>
				</div>
			</div> : ''}
		</div>
	);
};

GroupGoals.propTypes = {
	group: PropTypes.object.isRequired,
};

export default GroupGoals;