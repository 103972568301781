import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { gameStepsData } from 'data/game-steps-data';
// Facilitator Components
import GameIntro 				from 'components/game-steps/facilitator/game-intro/game-intro';
import AreaIntro 				from 'components/game-steps/facilitator/area-intro/area-intro';
import AreaScore				from 'components/game-steps/facilitator/area-score/area-score';
import FinalArea 				from 'components/game-steps/facilitator/final-area/final-area';
import DebateInfo 				from 'components/game-steps/facilitator/debate-info/debate-info';
import VoteOverview 			from 'components/game-steps/facilitator/vote-overview/vote-overview';
import FinalGroupScores 		from 'components/game-steps/facilitator/final-group-scores/final-group-scores';
import VoteResultController 	from 'components/game-steps/facilitator/vote-result/vote-result-controller';
// Student Components
import Group 					from 'components/game-steps/student/group/group';
import AreaInfo 				from 'components/game-steps/student/area-info/area-info';
import Attention 				from 'components/game-steps/student/attention/attention';
import VoteController			from 'components/game-steps/student/vote/vote-controller';
import Loading from 'components/loading/loading';

const gameSteps = {
	// facilitator
	areaIntro: 			{component: AreaIntro},
	gameIntro: 			{component: GameIntro},
	debateInfo: 		{component: DebateInfo},
	voteResult: 		{component: VoteResultController},
	voteOverview: 		{component: VoteOverview},
	areaScore:			{component: AreaScore},
	finalGroupScores:	{component: FinalGroupScores},
	finalArea:			{component: FinalArea},

	// student
	vote: 				{component: VoteController},
	group: 				{component: Group},
	areaInfo: 			{component: AreaInfo},
	attention: 			{component: Attention},
};

class GameController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	/**
	 * Mounting component
	 */
	componentDidMount = () => {
		if (this.props.game === undefined || this.props.game.gameStepId === undefined) {
			this.props.updateGame({gameStepId: gameStepsData[0].id});
		}
	}

	/**
	 * Gets the current gamestep and the current gamestep data
	 */
	getCurrentGameStep = () => {
		const gameStepIndex = (gameStepsData.findIndex((step) => {
			return step.id === this.props.game.gameStepId;
		}));

		let nextPage = null;
		if (gameStepIndex !== -1) {
			const gameStep = gameStepsData[gameStepIndex];
			nextPage = this.props.isFacilitator ? gameStep.facilitatorPage : gameStep.studentPage;
		}
		
		return {currentGameStepId: nextPage, gameStepData: gameStepsData[gameStepIndex]};
	}

	/**
	 * Go to a specific game step with given id
	 * @param {string} id 
	 */
	handleGoToSpecificGameStep = (id) => {
		if (!this.props.isFacilitator) {
			return;
		}

		const gameStepIndex = (gameStepsData.findIndex((step) => {return step.id === id;}));
		const gameStep = gameStepsData[gameStepIndex];

		if (gameStepIndex !== -1) {
			this.props.updateGame({gameStepId: gameStep.id});
		}
	}

	/**
	 * Go to next / previous game step
	 * @param {string} direction
	 * @returns 
	 */
	handleGoToGameStep = (direction) => {
		if (!this.props.isFacilitator) {
			return;
		}
		this.setState({loading: true});
		const game = this.props.game;

		const gameStepId = (game && gameStepsData.some((step) => {return step.id === game.gameStepId;})
			? game.gameStepId 
			: gameStepsData[0].id
		);
		// Getting current step index from game steps array
		const gameStepIndex = gameStepsData.findIndex((step) => {return step.id === gameStepId;});

		let gameStep;

		/* Next / Previous game step */
		if (direction === 'next') {
			gameStep = gameStepsData[gameStepIndex + 1];
		} else if (direction === 'prev' && gameStepIndex > 0) {
			gameStep = gameStepsData[gameStepIndex - 1];
		}

		if (gameStep) {
			this.props.updateGame({gameStepId: gameStep.id}).then(() => {
				/* Success */
				this.setState({loading: false});
			}).catch((error) => {
				console.error('Error updating game step: ', error);
			});
		}
	}

	/**
	 * Render component
	 */
	render = () => {
		const gameStepData = this.getCurrentGameStep();
		
		if (this.props.game === null || this.props.game === undefined || this.state.loading) {
			return <Loading 
				handleLogout={this.props.handleLogout}
				alternativeText={gameStepData.currentGameStepId === 'finalArea' ? 'Bygger landskabet...' : null}
			/>;
		}

		let CurrentComponent = null;

		if (gameSteps.hasOwnProperty(gameStepData.currentGameStepId)) {
			CurrentComponent = gameSteps[gameStepData.currentGameStepId].component;
		}

		if ((CurrentComponent === undefined || CurrentComponent === null) && this.props.isFacilitator) {
			return <Loading 
				handleLogout={this.props.handleLogout}
			/>;
		}

		return (
			<CurrentComponent
				handleLogout={this.props.handleLogout}
				handleGoToGame={this.props.handleGoToGame}
				handleGoToGameStep={this.handleGoToGameStep}
				handleGoToSpecificGameStep={this.handleGoToSpecificGameStep}
				gameStepData={gameStepData.gameStepData}
				updateGame={this.props.updateGame}
				gameCode={this.props.game.id}
				game={this.props.game}
				group={this.props.group}
				groups={this.props.groups}
				updateGroup={this.props.updateGroup}
			/>
		);
	}
}

GameController.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	handleLogout: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func,
	updateGame: PropTypes.func,
	game: PropTypes.object,
	group: PropTypes.object,
	groups: PropTypes.array,
	updateGroup: PropTypes.func,
};

export default GameController;