const infoTabs = [
	{
		title: 'Om spillet', 
		text: `Natursynsspillet er et digitalt understøttet forhandlingsrollespil til jer der ønsker at arbejde med demokratisk dannelse og natursyn.
		<br><br>
		Vi har alle forskellige syn på, hvordan vores natur skal prioriteres og bruges. I spillet lærer eleverne om dette på en sjov og medrivende måde. `
	},

	{
		title: 'Historien', 
		text: `Storå løber gennem to kommuner og former landskabet mellem dem. De to kommuner ønsker at gentænke og udvikle det område, der binder dem sammen.
		<br><br>
		Kommunerne har lavet forslag til små og store ændringer af landskabet i samarbejde med borgergrupper og interesseorganisationer. Nu er det tid at beslutte, hvad der skal gøres.`
	},

	{
		title: 'Spilforløb', 
		text: `Hvert af de forslag, der kan vedtages, giver landskabet point i en eller flere naturværdier:
		<br><br>
		<div style="font-weight: 500">
			<ul>
				<li>Adgang og herlighed</li>
				<li>Jagt og lystfiskeri</li>
				<li>CO2-reduktion og klimatilpasning</li>
				<li>Produktion</li>
				<li>Biodiversitet</li>
			</ul>
		</div>
		<br>
		I spiller seks forskellige organisationer. I har hver jeres syn på, hvordan naturen skal bruges. I skal bruge forhandling og taktik, for at få overbevist de andre, om jeres natursyn.
		<br><br>
		For at vinde spillet skal I sikre, at landskabet får så mange point som muligt i de naturværdier, der er vigtige for jer.`
	}
];

module.exports = {infoTabs};