import React, { useState} from 'react';
import PropTypes from 'prop-types';
import './area-info.scss';
import { getDebateData, getRoundNumber } from 'helpers/area-data-helper';
import { pageUiTexts, generalUiTexts } from 'data/ui-texts';
import InfoCard from 'components/ui/info-card/info-card';
import GroupSidebar from 'components/ui/group-sidebar/group-sidebar';
import GroupHint from 'components/ui/group-hint/group-hint';
import Area from 'components/ui/area/area';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import { rules } from 'data/debate-data';

const AreaInfo = ({handleLogout, gameStepData, group, game}) => {
	const debateData = getDebateData(gameStepData.area);
	const [showPopup, setShowPopup] = useState(false);

	// Used to switch between subarea
	const [activeSubAreaIndex, setActiveSubArea] = useState(0);

	const roundNumber = getRoundNumber(gameStepData.area);

	return (
		<div className='AreaInfo'>
			{showPopup && <Popup
				title={generalUiTexts.theRules}
				message={rules}
				messageClass={'rules'}
				cancelButtonText={generalUiTexts.ok}
				onContinueButtonClick={() => {}}
				onCancelButtonClick={() => {setShowPopup(false);}}
				showContinue={false}
				textAlign='left'
			/>}

			{/* Area background svg */}
			<Area position={'side'} 
				areaId={gameStepData.area}/>
			
			{/* Group Information Sidebar */}
			<div className='AreaInfo-groupInfoBar'>
				<GroupSidebar group={group} game={game}/>

				<div className='AreaInfo-logoutButton'>
					<Button
						text={generalUiTexts.logout}
						classes={['logout']}
						onClick={() => {handleLogout();}}
					/>
				</div>
			</div>

			<div className='AreaInfo-content'>
				{/* Body containing current are information */}
				<div className='AreaInfo-body'>
					<div className='AreaInfo-subAreaInfoWrapper'>
						<div className='AreaInfo-description'>
							<span>{debateData.subArea[activeSubAreaIndex].description}</span>
						</div> 
						<div className='AreaInfo-infoCard'>
							<InfoCard
								headerText={
									pageUiTexts.whatShouldHappen
									+ ' '
									+ debateData.subArea[activeSubAreaIndex].title
									+ '?'
								}
								contentList={debateData.subArea[activeSubAreaIndex].actions}
								collapseable={true}
							/>
						</div>
					</div>
				</div>

				{/* Header containing area buttons */}
				<div className='AreaInfo-header'>
					<div className='AreaInfo-subAreaWrapper'>
						{debateData.subArea.map((subject, index) => {
							let className = 'AreaInfo-subAreaButton castShadow';
							
							return (
								<div 
									key={index} 
									className={activeSubAreaIndex === index ? className + ' Active' : className} 
									onClick={() => {setActiveSubArea(index);}}
								>
									<div className='AreaInfo-subAreaButtonText'>
										{subject.title}
									</div>
								</div>
							);
						})}
									
						<div className='AreaInfo-rulesButton'>
							<Button
								text={generalUiTexts.rules}
								classes={['rules']}
								onClick={() => {setShowPopup(true);}}
							/>
						</div>
					</div>
					<GroupHint group={group} gameStepData={gameStepData} startOpen={(roundNumber === 1)}/>
				</div>
			</div>
		</div>
	);
};

AreaInfo.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	gameStepData: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
};

export default AreaInfo;