import React, { useState} from 'react';
import PropTypes from 'prop-types';
import './debate-info.scss';
import Button from 'components/ui/button/button';
import { getDebateData, getRoundNumber } from 'helpers/area-data-helper';
import { pageUiTexts, pageTitles, continueButtonTexts, generalUiTexts } from 'data/ui-texts';
import Timer from 'components/ui/timer/timer';
import GameCode from 'components/ui/game-code/game-code';
import InfoCard from 'components/ui/info-card/info-card';
import Popup from 'components/ui/popup/popup';
import { rules } from 'data/debate-data';
import Area from 'components/ui/area/area';
import NextPageButton from 'components/ui/next-page-button/next-page-button';
import appConfig from 'config/app.config';
import PdfLink from 'components/ui/pdf-link/pdf-link';
import teachersGuide from 'assets/pdfs/lærervejledning.pdf';

const DebateInfo = ({handleGoToGame, handleGoToGameStep, gameStepData, gameCode}) => {
	const debateData = getDebateData(gameStepData.area);
	const [showPopup, setShowPopup] = useState(false);

	const roundNumber = getRoundNumber(gameStepData.area);
	let debateIntroText = '';

	if (roundNumber === 1) {
		debateIntroText = pageUiTexts.debateIntro;
	} else {
		debateIntroText = pageUiTexts.debateIntro2;
	}

	// Used to expand and contract area info
	const [expandedAreaId, setExpandedAreaId] = useState(null);
	const expandArea = (id) => {
		if (id === expandedAreaId) {
			setExpandedAreaId(null);
		} else {
			setExpandedAreaId(id);
		}
	};

	return (
		<div className="DebateInfo">
			{/* Area background svg */}
			<Area areaId={gameStepData.area} />

			{showPopup && <Popup
				title={generalUiTexts.theRules}
				message={rules}
				messageClass={'rules'}
				cancelButtonText={generalUiTexts.ok}
				onContinueButtonClick={() => {}}
				onCancelButtonClick={() => {setShowPopup(false);}}
				showContinue={false}
				textAlign='left'
			/>}

			<div className='DebateInfo-contentWrapper'>
				{/* Information sidebar */}
				<div className='DebateInfo-sidebar'>
					{/* Areas */}
					<div className='DebateInfo-sidebarAreas'>
						<div className='DebateInfo-sidebarDescriptionBox'>
							<div className='DebateInfo-sidebarDescriptionTitle'>
								<span>{generalUiTexts.actions}</span>
							</div>
							<div className='DebateInfo-sidebarDescriptionHeader'>
								<div className='DebateInfo-sidebarDescriptionText'
									dangerouslySetInnerHTML={{__html: debateIntroText}}/>
							</div>
						</div>
						{debateData.subArea.map((subArea, index) => {
							let descriptionClass = 'DebateInfo-sidebarAreaDescription';
							let areaClass = 'DebateInfo-sidebarAreaName castShadow';
							let triangleClass = 'DebateInfo-triangle';

							if (expandedAreaId === index) {
								descriptionClass += ' active';
								areaClass += ' active';
								triangleClass += ' active';
							};

							return (
								<div key={index} className='DebateInfo-sidebarArea'>
									<div className={areaClass} 
										onClick={() => {expandArea(index);}}>
										<span>{subArea.title}</span>
									</div>
									<div className={triangleClass}>
										<div className='DebateInfo-arrow'/>
									</div>
									
									{/* Subarea description popup */}
									<div className={descriptionClass}>
										<div className='DebateInfo-description castShadow'>
											<div className='DebateInfo-descriptionTitle'>{subArea.title}</div>
											<span>{subArea.description}</span>
										</div>
										<InfoCard
											title={pageUiTexts.whatShouldHappen 
												+ ' ' 
												+ subArea.title 
												+ '?'}
											contentList={subArea.actions}
											closeable={true}
											closeDialogue={() => {expandArea(null);}}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				{/* Header */}
				<div className='DebateInfo-titleWrapper'>
					<Timer countDown={15} title={pageTitles.debateInfoTitle}/>
				</div>

				<div className='DebateInfo-rulesButton'>
					<Button
						text={generalUiTexts.rules}
						classes={['rules']}
						onClick={() => {setShowPopup(true);}}
					/>
				</div>
			</div>

			{/* Contains gamecode and navigation buttons */}
			<div className='DebateInfo-navigation'>
				<div className='DebateInfo-navigationButtons'>
					<PdfLink link={teachersGuide} text={generalUiTexts.teachersGuide} styleClass='dark'/>
					<div>
						<Button
							text={generalUiTexts.lobby}
							classes={['lobby']}
							onClick={() => {handleGoToGame();}}
						/>
						{appConfig.env !== 'production' ? <Button
							text={generalUiTexts.back}
							onClick={() => {handleGoToGameStep('prev');}}
						/> : ''}
					</div>
				</div>

				<GameCode gameCode={gameCode}/>

				<NextPageButton handleGoToGameStep={handleGoToGameStep} text={continueButtonTexts.debate}/>
			</div>
		</div>
	);
};

DebateInfo.propTypes = {
	handleGoToGame: PropTypes.func.isRequired,
	handleGoToGameStep: PropTypes.func.isRequired,
	gameStepData: PropTypes.object.isRequired,
	gameCode: PropTypes.string.isRequired,
};

export default DebateInfo;