const pointTypes = [
	{
		id: 'acc', 
		name: 'Adgang og herlighed', 
		description: `Hvordan er adgangen til naturen?
		<br><br>God eller dårlig? Kan man gå i skoven eller langs åen?
		<br><br>Kan man cykle eller ride en tur?
		<br>Og hvor smukt og varieret er kulturlandskabet?`
	},
	{
		id: 'fre', 
		name: 'Jagt og lystfiskeri',
		description: `Hvordan er mulighederne for at gå på jagt eller fiske i naturen?
		<br><br>Tror I, der er vildt at jage og fisk at fange?`
	},
	{
		id: 'eco', 
		name: 'CO2-reduktion og klimatilpasning',
		description: `Kan den måde vi bruger naturen være med til at mindske vores CO2-aftryk?
		<br><br>Kan landskabet være med til at sikre os mod oversvømmelser - eller andre effekter af klimaforandringer? `
	},
	{
		id: 'pro', 
		name: 'Produktion',
		description: `Kan vi producere korn, kød, tømmer, oplevelser eller andet på jorden?
		<br><br>Er der noget i området, som kan skabe arbejdspladser?`
	},
	{
		id: 'bio', 
		name: 'Biodiversitet',
		description: `Hvor vild er naturen?
		<br><br>Er der en høj biodiversitet?
		<br>Altså: Er der levesteder for mange forskellige dyr og planter i området?`
	}
];

const debateData = [
	{
		id: 'area1',
		title: 'Skoven og søen',
		disposition: 'Denne del af landskabet rummer en skov, en kunstig sø, der blev anlagt i 1910 og så overdrevet. Overdrevet bruges primært til landbrug.',
		subArea: [
			{
				id: 1,
				title: 'Vestskoven',
				description: 'En tredjedel af skoven er offentlig skov. Resten er privat, men der er offentlig adgang om dagen.',
				actions: [
					{
						id: 1,
						title: 'Udsæt vildsvin',
						isExpensive: false,
						description: 'Vi udsætter vildsvin i skoven. De roder i skovbunden med deres tryner og spiser bog og agern. De tramper og gnubber sig op ad træerne. Det er med til at skabe en mere varieret natur. Man kan gå på jagt efter vildsvin, for at holde bestanden ned. Der bliver sat lave hegn op, for at beskytte markerne i nærheden.',
						value: [
							{point: 2, type: pointTypes[1].id},
							{point: 2, type: pointTypes[4].id},
						]
					},
					{
						id: 2,
						title: 'Mountainbike-sti',
						isExpensive: false,
						description: 'Vi laver en sti til mountainbikes i den offentlige del af skoven. Stien fører hen over nogle bakker, som ligger på grænsen til den private skov.',
						value: [
							{point: 2, type: pointTypes[0].id},
						]
					},
					{
						id: 3,
						title: 'Uberørt skov',
						isExpensive: true,
						description: 'Kommunen opkøber halvdelen af den private skov. Herefter får al kom-munens skov lov at stå som urørt skov. Den urørte skov bliver indhegnet og der bliver sat køer og heste ud, som kan græsse og skabe en mere varieret natur. Det gør, at adgangen for men-nesker bliver lidt mere begrænset. I den private skov kan der stadig drives jagt.',
						value: [
							{point: 2, type: pointTypes[4].id},
							{point: 2, type: pointTypes[2].id},
						]
					}
				],
			},
			{
				id: 2,
				title: 'den kunstige sø',
				description: 'Søen er kunstig og anlagt i 1910. Den er ejet af kommunen. Omkring søen ligger enge. De sørger for, at der ikke bliver udvasket for mange næringsstoffer til søen og de vandløb, som er i området. I og omkring søen lever fisk, dyr og fugle, som ikke er fredede.',
				actions: [
					{
						id: 1,
						title: 'Fjern søen og lav enge',
						isExpensive: true,
						description: 'Søen fjernes så åen kan flyde i sit naturlige leje. Det er godt for fiskebestanden. Området får lov at stå som enge. Nogle af dem udlejes til landmænd til græsning.',
						value: [
							{point: 2, type: pointTypes[1].id},
							{point: 2, type: pointTypes[4].id},
						]
					},
					{
						id: 2,
						title: 'Fjern søen og lav marker',
						isExpensive: false,
						description: 'Vi fjerner søen, så åen kan flyde frit i sit naturlige leje. Det er godt for fiskebestanden. Det skaber også nye marker. De kan sælges til landmænd, som kan dyrke på dem.',
						value: [
							{point: 2, type: pointTypes[3].id},
							{point: 1, type: pointTypes[1].id},
						]
					},
					{
						id: 3,
						title: 'Lav badesø',
						isExpensive: false,
						description: 'Søens vand er rent, så der har længe været ønske om at gøre søen til badesø. Kommunen etablerer en sti og en badebro.',
						value: [
							{point: 2, type: pointTypes[0].id},
							{point: 1, type: pointTypes[3].id},
						]
					}
				],
			},
			{
				id: 3,
				title: 'Overdrevet',
				description: 'Et overdrev er et tørt område, som i gamle dage blev brugt til græsning. Overdrevet her bliver mest brugt til marker - og en lille del bliver græsset. Overdrevet skærer ned gennem området. Der er ikke adgang for færdsel. Det gør det svært for folk, at komme til skoven fra den ene kommune.',
				actions: [
					{
						id: 1,
						title: 'Tillad adgang',
						isExpensive: false,
						description: 'Kommunen laver en aftale med lodsejeren om, at der er fri adgang til området. Så kan folk vandre i markskel og langs søen.',
						value: [
							{point: 2, type: pointTypes[0].id},
						]
					},
					{
						id: 2,
						title: 'Byg solcelleanlæg',
						isExpensive: false,
						description: 'Vi sætter solceller på en del af overdrevet. En solcellepark vil kunne levere grøn strøm.',
						value: [
							{point: 2, type: pointTypes[3].id},
							{point: 2, type: pointTypes[2].id},
						]
					},
					{
						id: 3,
						title: 'Plant træer og buske',
						isExpensive: false,
						description: 'Kommunen aftaler med lodsejerne, at de planter flere levende hegn og små lunde. Det skaber mere plads til natur, øger biodiversiteten og er smukt i landskabet.',
						value: [
							{point: 1, type: pointTypes[4].id},
							{point: 1, type: pointTypes[1].id},
						]
					}
				],
			}
		]
	},
	{
		id: 'area2',
		title: 'Ådalen',
		disposition: 'Åen løber gennem et bakket område. Den blev rettet ud, da søen blev anlagt. Man kan stadig ane det oprindelige åløb i landskabet.',
		subArea: [
			{
				id: 1,
				title: 'Åen',
				description: 'Åen blev rettet ud da man lavede den kunstige sø. Der er ofte risiko for oversvømmelser i den østlige del af området. ',
				actions: [
					{
						id: 1,
						title: 'Skær grøde',
						isExpensive: false,
						description: 'Vi skærer jævnligt åens planter ned, så den kan lede mere vand væk. På den måde bliver markerne drænet bedre - og det giver bedre afgrøder.',
						value: [
							{point: 2, type: pointTypes[3].id},
						]
					},
					{
						id: 2,
						title: 'Genslynge åen',
						isExpensive: true,
						description: 'Åen skal igen have lov at slynge sig og løbe i sit naturlige leje. Det vil være godt for åens biodiversitet og for fiskeriet. Det kræver, at vi inddrager en del jord, som lige nu bruges til marker.',
						value: [
							{point: 1, type: pointTypes[0].id},
							{point: 2, type: pointTypes[1].id},
							{point: 2, type: pointTypes[4].id},
						]
					},
					{
						id: 3,
						title: 'Mere friluftsliv',
						isExpensive: false,
						description: 'Vi lader åen være, men gør plads til kanoer, shelters, fugletårne og fiskeplatforme for folk med handicap.',
						value: [
							{point: 2, type: pointTypes[0].id},
							{point: 1, type: pointTypes[1].id},
						]
					}
				],
			},
			{
				id: 2,
				title: 'Ådalen',
				description: 'Den lavtliggende jord omkring åen bruges til landbrug. Oprindeligt var det et vådområde, som blev drænet og gjort til landbrugsjord.',
				actions: [
					{
						id: 1,
						title: 'Udtag lavbundsjorder',
						isExpensive: true,
						description: 'De marker som ligger tæt på åen skal ikke dyrkes mere. Hvis de får lov at blive oversvømmet, vil de holde fast på CO2 i jorden. De landmænd som ejer jorden får betaling som kompensation for de afgrøder, de ikke kan dyrke.',
						value: [
							{point: 3, type: pointTypes[2].id},
							{point: 1, type: pointTypes[1].id}
						]
					},
					{
						id: 2,
						title: 'Produktion af biomasse',
						isExpensive: false,
						description: 'Vi planter piletræer på markerne. Pil vokser meget hurtigt. Når pil vokser, optager planterne CO2 fra luften og næringsstoffer fra jorden. Pilegrene kan bruges til biomasse, som brændes af i et forbrændingsanlæg og bliver til CO2-neutral energi.',
						value: [
							{point: 2, type: pointTypes[3].id},
							{point: 1, type: pointTypes[2].id},
						]
					},
					{
						id: 3,
						title: 'Udsæt dyr',
						isExpensive: false,
						description: 'Vi sætter græssende dyr ud og etablerer vandrestier. Køerne ejes af et koklapperlaug, som betaler for at bruge jorden.',
						value: [
							{point: 1, type: pointTypes[4].id},
							{point: 1, type: pointTypes[3].id},
						]
					}
				],
			},
			{
				id: 3,
				title: 'Bakkedraget',
				description: 'Bakkedraget består af ret frugtbar jord, der er god at dyrke. Under jorden er der store mængder grus.',
				actions: [
					{
						id: 1,
						title: 'Grav grus',
						isExpensive: false,
						description: 'Kommunen giver tilladelse til at grave grus. Efter der er gravet grus i 5 år, kan de store huller bruges til friluftsliv. Der kan komme gangstier, kælkebakker og en sø til lystfiskeri.',
						value: [
							{point: 2, type: pointTypes[3].id},
							{point: 1, type: pointTypes[1].id},
							{point: 1, type: pointTypes[0].id},
						]
					},
					{
						id: 2,
						title: 'Klimaskov',
						isExpensive: true,
						description: 'Vi planter en klimaskov på bakketoppen. Træerne binder CO2, skaber nye levesteder og øger biodiversiteten. Skoven giver også nye muligheder for friluftslivet.',
						value: [
							{point: 2, type: pointTypes[2].id},
							{point: 2, type: pointTypes[4].id},
							{point: 1, type: pointTypes[0].id},
						]
					},
					{
						id: 3,
						title: 'Dyrk mere intensivt',
						isExpensive: false,
						description: 'Kommunen giver tilladelse til, at landmanden kan dyrke jorden  mere intensivt. Landmanden må fx gøde mere for at få flere afgrøder. De naturlige bakker bevares.',
						value: [
							{point: 1, type: pointTypes[3].id},
							{point: 1, type: pointTypes[0].id},
						]
					}
				],
			}
		]
	},
	{
		id: 'area3',
		title: 'Kysten',
		disposition: 'Den gamle vandmølle præger området. Engene rundt om møllen bruges først og fremmest til græsning og dyrkning. Efter møllen løber åen ret lige ud mod kysten.',
		subArea: [
			{
				id: 1,
				title: 'Mølleengen',
				description: `Mølleengen bruges mest til græssende dyr. Møllen ligger smukt og er et velbesøgt udflugtsmål. Møllen er slidt og trænger til renovering. Fisketrappen virker ikke, så vandrefisk kan ikke komme til deres gydepladser.`,
				actions: [
					{
						id: 1,
						title: 'Fjern møllen',
						isExpensive: false,
						description: 'Fjern møllen og lad åen flyde frit. Engen får lov at stå. Så kan den blive oversvømmet, når der er meget regn, og beskytte byerne i nærheden.',
						value: [
							{point: 1, type: pointTypes[4].id},
							{point: 1, type: pointTypes[2].id},
							{point: 2, type: pointTypes[1].id}
						]
					},
					{
						id: 2,
						title: 'Oplevelsescenter',
						isExpensive: true,
						description: 'Den gamle mølle bliver indrettet til oplevelsescenter. Her kan folk få historien om vandkraft, landbrug og områdets natur. Åen blive ført uden om møllen, så fiskene kan vandre forbi møllen og længere op i åen.',
						value: [
							{point: 1, type: pointTypes[4].id},
							{point: 1, type: pointTypes[3].id},
							{point: 1, type: pointTypes[0].id},
							{point: 2, type: pointTypes[1].id},
						]
					},
					{
						id: 3,
						title: 'Sluse',
						isExpensive: false,
						description: 'Neden for møllen bliver der sat en sluse op. Slusen kan lukke for vandet og forhindre oversvømmelse, når storm eller højvande presser havet ind i åen.',
						value: [
							{point: 2, type: pointTypes[2].id},
						]
					}
				],
			},
			{
				id: 2,
				title: 'Strandengen',
				description: `Strandengen vokser ret frit, men bruges af og til til fårehold. Der er dårlig adgang til engen. 
				Naturmæssigt er området ikke specielt spændende. Det er ikke særlig smukt og har ikke høj biodiversitet. `,
				actions: [
					{
						id: 1,
						title: 'Græsning på strandengen',
						isExpensive: false,
						description: 'Strandengen bliver græsset og naturen plejet med det mål at øge strandengens biodiversitet.',
						value: [
							{point: 1, type: pointTypes[4].id},
							{point: 1, type: pointTypes[1].id},
						]
					},
					{
						id: 2,
						title: 'Dræn engen',
						isExpensive: true,
						description: 'Strandengen bliver drænet, så der kan bygges sommerhuse og anlægges et vandland. Der bliver bedre adgang til stranden. Investorer vil gerne lægge deres penge her.',
						value: [
							{point: 3, type: pointTypes[3].id},
							{point: 1, type: pointTypes[0].id},
						]
					},
					{
						id: 3,
						title: 'Dyrk engen',
						isExpensive: false,
						description: 'Strandengen drænes og pløjes op til landbrug. Der bliver etableret stier, så der er bedre adgang til stranden.',
						value: [
							{point: 2, type: pointTypes[3].id},
							{point: 1, type: pointTypes[0].id},
						]
					}
				]
			},
			{
				id: 3,
				title: 'Kyststrækningen',
				description: 'Kyststrækningen har en fin strand med gode bølger til surfing. Den er dog ikke særligt nemt at komme til.',
				actions: [
					{
						id: 1,
						title: 'Beskyttelse',
						isExpensive: false,
						description: 'Dele af kysten bliver spærret af, så man ikke kan gå derned. På den måde får dyre- og planteliv fred til at udvikle sig.',
						value: [
							{point: 1, type: pointTypes[1].id},
							{point: 2, type: pointTypes[4].id},
						]
					},
					{
						id: 2,
						title: 'Bedre adgang',
						isExpensive: false,
						description: 'Kommunen lægger en sti ned til stranden. Der bliver også lavet en lille p-plads, en badebro og måske en isbutik. Det kan skabe turisme og arbejdspladser.',
						value: [
							{point: 2, type: pointTypes[0].id},
							{point: 1, type: pointTypes[3].id},
						]
					},
					{
						id: 3,
						title: 'Vindmøller',
						isExpensive: false,
						description: 'Nær kysten bliver der sat vindmøller op. I havet omkring møllerne bliver der dyrket muslinger.',
						value: [
							{point: 3, type: pointTypes[2].id},
							{point: 1, type: pointTypes[3].id},
						]
					}
				],
			}
		]
	}
];

const debateGroupHints = [
	// -------- AREA1 -------- //
	{
		groupId: 1, // Landmænd og skovejere
		areaId: 'area1',
		hints: [
			'Søen hjælper til at afvaske næringsstoffer fra jorden og giver jer dermed bedre mulighed for at gøde jorden.',
			'Vildsvin kan godt spise/ødelægge en del afgrøder og hegn er sjældent 100% sikre mod vildsvin.',
			'En mountainbikesti vil måske skræmme vildtet væk fra dele af skoven. Det kan gøre det sværere at gå på jagt i den private skov.'
		]
	},
	{
		groupId: 2, // Naturens venner
		areaId: 'area1',
		hints: [
			'Det er ikke godt for livet i åen at fjerne søen og få mere landbrug.',
			'Hvis flere får adgang til naturen, bliver der mindre ro og plads til vilde dyr, fugle, som let bliver skræmt og forstyrret af mennesker.'
		]
	},
	{
		groupId: 3, // Friluftsfolket
		areaId: 'area1',
		hints: [
			'Folk må ikke færdes på landbrugsjord.'
		]
	},
	{
		groupId: 4, // Klimakæmperne
		areaId: 'area1',
		hints: [
			'Det er muligt at anlægge et solcelleanlæg, så der er plads til naturen imellem solcellerne. Det kan give bedre biodiversitet end en mark.',
			'Måske kan det give en bedre biodiversitet at fjerne søen.',
			'Mere landbrug er ikke klimavenligt.'
		]
	},
	{
		groupId: 5, // Jægerne og lystfiskerne
		areaId: 'area1',
		hints: [
			'Hvis flere vandrer og cykler i skoven, bliver vildtet forstyrret.',
			'Vildsvin roder og skaber mere biodiversitet.',
			'Åen kan blive til godt fiskevand. Men der er dårlig adgang til åen flere steder.'
		]
	},
	{
		groupId: 6, // Erhvervsgruppen
		areaId: 'area1',
		hints: [
			'Hvis vi har skov og landbrug, så får vi flere jobs i kommunen. Både i skoven og på gården, men også til folk, som forarbejder fx korn, mælk og træ.',
			'Engene omkring søen kan optage næringsstoffer fra landbrugsjorden - så mindre næring når ned til søen.'
		]
	},
	
	// -------- AREA 2 -------- //
	{
		groupId: 1, // Landmænd og skovejere
		areaId: 'area2',
		hints: [
			'Det er fint at dyrke pil, men det kræver en del omstilling.',
			'Hvis åen flyder bedre, så giver markerne større udbytte.', 
			'Hvis de lavtliggende jorde ned til åen bliver taget ud, så vi ikke kan dyrke på dem, kan området måske lejes ud til jagt.', 
		]
	},
	{
		groupId: 2, // Naturens venner
		areaId: 'area2',
		hints: [
			'Køer, heste og får græsser, tramper og skider. Det er tit godt for biodiversiteten i et område.',
			'Klimaskov skaber en mere varieret natur. Det er godt for mange dyr og planter.',
			'Hvis åen bliver slynget igen, er det godt for dyr og planter. Men området bliver ikke lettere at færdes i.'
		]
	},
	{
		groupId: 3, // Friluftsfolket
		areaId: 'area2',
		hints: [
			'En grusgrav kan blive god for området. Både fordi der er penge i grus - og fordi området bliver spændende for friluftslivet bagefter.',
			'Hvis åen bliver slynget igen, vil det give fine muligheder for fiskeri og jagt.'
		]
	},
	{
		groupId: 4, // Klimakæmperne
		areaId: 'area2',
		hints: [
			'Landbrugsjord som ligger lavt afgiver meget CO2. Hvis vi stopper dyrkning og lader dem bliver oversvømmet, vil CO2 bliver holdt tilbage.',
			'Hvis vi dyrker pil, vil de optage CO2. Det er godt for klimaet. Men en pilemark, hvor der kun er pil, er ikke god for biodiversiteten.'
		]
	},
	{
		groupId: 5, // Jægerne og lystfiskerne
		areaId: 'area2',
		hints: [
			'Hvis vi skærer grøde - altså planter - væk fra åen, så får fiskene færre steder at gemme sig.',
			'Hvis vi ikke dyrker de jorde, som ligger lavt helt ned til åen, så kan der blive gode levesteder for dyr - fx rådyr og andet vildt.'
		]
	},
	{
		groupId: 6, // Erhvervsgruppen
		areaId: 'area2',
		hints: [
			'Bakkedraget er smukt, og det vil være godt at bevare. En grusgrav bliver først et pænt område om mange år.',
			'Pil til biomasse er en fin afgrøde, som også er god for klimaet.'
		]
	},
	
	// -------- AREA 3 -------- //
	{
		groupId: 1, // Landmænd og skovejere
		areaId: 'area3',
		hints: [
			'Der er gode penge i vindkraft, og blåmuslinger er nemme at dyrke.',
			'Hvis møllen bliver fjernet, så kan fiskene bedre leve i åen.'
		]
	},
	{
		groupId: 2, // Naturens venner
		areaId: 'area3',
		hints: [
			'Hvis åen løber frit, vil det være godt for dyrelivet.',
			'Et oplevelsescenter vil garanteret kunne bruges af mange forskellige grupper.'
		]
	},
	{
		groupId: 3, // Friluftsfolket
		areaId: 'area3',
		hints: [
			'Det er en god strand. Hvis man bedre kan komme til den, så vil mange flere bruge den.',
			'Hvis åen strømmer frit, eller hvis kysten beskyttes, så giver det bedre mulighed for lystfiskeri.'
		]
	},
	{
		groupId: 4, // Klimakæmperne
		areaId: 'area3',
		hints: [
			'Vindmøller laver grøn strøm, som der er stor efterspørgsel på.',
			'Hvis engen kan oversvømmes, beskytter det området mod oversvømmelser.' 
		]
	},
	{
		groupId: 5, // Jægerne og lystfiskerne
		areaId: 'area3',
		hints: [
			'Ved at pleje engen eller ved at mindske adgang, så får dyr bedre levevilkår.',
			'Hvis møllen bliver fjernet, eller hvis der bliver lavet omløb på åen, kan ørreder leve i åen.'
		]
	},
	{
		groupId: 6, // Erhvervsgruppen
		areaId: 'area3',
		hints: [
			'Bedre adgang til stranden vil være godt for turismen.',
			'En sluse vil være en billig metode til at klimasikre området.'
		]
	}
];

const voteData = [1,3,6];

const pointScalas = [3, 2, 1];

const roundIntro = [
	{title: 'Debat', 
	description: `Området består af tre under-områder.
	For hvert underområde er der mulighed for tre tiltag.
	Der kan kun gennemføres ét tiltag i hvert underområde.<br><br>
	Hvert tiltag giver point i forskellige naturværdier.
	<br><br>
	Tal med de andre grupper om deres ønsker - og find ud af, hvor I kan lave alliancer og aftaler.`},
	{title: 'Afstemning', 
	description: `Efter debatten skal I stemme om hvilke tiltag, der skal gennemføres.
	<br><br>
	Hver gruppe har tre stemmer med værdierne 1, 3 og 6. I skal fordele stemmerne mellem de tre underområder.
	<br><br>
	I kan læse de præcise regler for afstemning i "Regler".`}, 
	{title: 'Resultat', 
	description: `Til sidst kan I se, hvordan de forslag, I har vedtaget, påvirker naturen og landskabet. 
	<br><br>
	Hvert tiltag giver desuden området point i de fem naturværdier.
	<br><br>
	Husk at forskellige naturværdier er vigtige for forskellige grupper.`}
];

const pointThresholds = [
	{level: 'small', limit: 30},
	{level: 'large', limit: 40}
];

const pointDescription =
`Jo flere point landskabet får i de naturværdier, som er vigtige for os, jo flere point får vi`;

const rules =
`<b>Grundlæggende</b><br>
Spillet forløber over tre runder. I hver runde skal grupperne vedtage ét tiltag for hvert af delområdets tre underområder.<br><br>

Tiltagene giver landskabet point i de fem naturværdier. Alt efter hvad grupperne har som mål får de point baseret på landskabets naturværdier. De får 3, 2 og 1 point alt efter hvor vigtigt målet er for dem.<br><br>

For at vinde skal man have mindst 25 point, det giver en lille sejr. Får man 30 point vinder man en stor sejr. Flere grupper kan vinde.<br><br>

<b>Afstemning</b><br>
Hver gruppe har tre indflydelsesbrikker med værdien 6, 3 og 1. Man kan kun stemme på ét tiltag i hvert underområde.<br><br>

Ved stemmelighed vinder tiltaget som flest grupper har stemt på. Er der stadig lighed vinder det øverste tiltag. Tiltagene vil blive arrangeret sådan at denne regel ikke favoriserer en bestemt naturværdi.<br><br>

Nogle tiltag er dyre (markeret med “$”). Der kan kun vedtages ét dyrt forslag per runde. Hvis mere end ét dyrt tiltag har fået flest stemmer, så bruges normale tiebreaker-regler. Så hvis et dyrt forslag vinder i både Åen og Bakkedraget, men i Bakkedraget har fået flest stemmer af de to, så bliver det for Bakkedraget vedtaget, mens nummer to tiltag vedtages i Åen.`;

module.exports = {debateData, voteData, pointTypes, pointScalas, debateGroupHints, rules, pointThresholds, roundIntro, pointDescription};