import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/button/button';
import { groupData } from 'data/group-data';
import { pointScalas, pointTypes } from 'data/debate-data';
import { pageUiTexts, pointText, generalUiTexts } from 'data/ui-texts';
import Area from 'components/ui/area/area';
import './group.scss';

const Group = ({handleLogout, group}) => {
	const groupInfo = groupData.find((data) => {return data.id === group.index;});

	return (
		<div className="Group">
			{/* Area background svg */}
			<Area position='bottom' areaId={'area1'}/>

			<div className='Group-leftColumn'>
				{/* Group Info Panel */}
				<div className='Group-infoPanel castShadow'>
					<div className={'Group-panelGroupIcon groupBackground' + group.index}>
						<div className={'Group-groupLogo groupLogo' + group.index}/>
						<div className='Group-panelTitle'>
							<span>{groupInfo.name}</span>
						</div>
					</div>
					<div className={'Group-panelDescription groupColor' + group.index}
					 dangerouslySetInnerHTML={{__html: groupInfo.text}}/>
						
				</div>
			</div>
			<div className='Group-rightColumn'>
				{/* Group Goals */}
				<div className='Group-goalPanelWrapper'>
					{groupInfo.pointScalas.map((pointScala, index) => {
						let panelTitle = pageUiTexts.primaryGoal;

						if (index === 1) {
							panelTitle = pageUiTexts.secondaryGoal;
						} else if (index === 2) {
							panelTitle = pageUiTexts.tertiaryGoal;
						}

						let scalaData = pointTypes.find((type) => {
							return type.id === pointScala;
						});

						let scalar = pointScalas[index];

						return (
							<div key={index} className='Group-goalPanel castShadow'>
								<div className={'Group-goalPanelHeader groupBackground' + group.index}>
									<div className='Group-goalPanelHeaderText'>
										<span>{panelTitle}</span>
									</div>
									<div className='Group-goalPanelHeaderText'>
										<span>{pointText.point + ' x' + scalar}</span>
									</div>
								</div>
								<div className='Group-goalPanelContent'>
									<div className={'Group-goalName groupColor' + group.index}>
										{scalaData.name}:
									</div>
									<div className={'Group-goalDescription groupColor' + group.index}
										dangerouslySetInnerHTML={{__html: scalaData.description}}/>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className='GroupSidebar-logoutButton'>
				<Button
					text={generalUiTexts.logout}
					classes={['logout']}
					onClick={() => {handleLogout();}}
				/>
			</div>
		</div>
	);
};

Group.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	group: PropTypes.object.isRequired,
};

export default Group;