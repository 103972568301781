import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';

const LoginFacilitator = (props) => {
	let {
		handleInput,
		handleLogin,
		setFacilitatorBoxType,
		email,
		password,
		feedback,
		isLoading
	} = props;

	return (
		<div className="LoginFacilitator">
			<form className="Login-form" onSubmit={handleLogin}>
				<div className="Login-header">
					<span>{loginUiTexts.teacherLogin}</span>
					<div className='Login-inputWrapper'>
						<input
							name="email"
							type="text"
							placeholder={loginUiTexts.email} 
							autoComplete="section-facilitatorlogin email"
							value={email ? email : ''} 
							onChange={(event)=>{handleInput(event);}}
						/>
						<input
							name="password"
							type="password"
							placeholder={loginUiTexts.password}
							autoComplete="section-facilitatorlogin password"
							value={password ? password : ''} 
							onChange={(event)=>{handleInput(event);}}
						/>
					</div>
				</div>
				<Button 
					isLoading={isLoading}
					classes={['login']}
					text={loginUiTexts.login} 
					onClick={handleLogin} 
				/>
				<p className="Login-errorMessage">{feedback}</p>
				<div 
					className="Login-userButton"
					onClick={()=>{setFacilitatorBoxType('resetPassword');}}
				>{loginUiTexts.forgotPassword}</div>
				<div 
					className="Login-userButton"
					onClick={()=>{setFacilitatorBoxType('createUser');}}
				>{loginUiTexts.createNewUser}</div>
			</form>
		</div>
	);
};

LoginFacilitator.propTypes = {
	handleLogin: PropTypes.func.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
};

export default LoginFacilitator;