import React from 'react';
import PropTypes from 'prop-types';
import './final-area.scss';
import Button from 'components/ui/button/button';
import GameCode from 'components/ui/game-code/game-code';
import appConfig from 'config/app.config';
import NextPageButton from 'components/ui/next-page-button/next-page-button';
import { continueButtonTexts, generalUiTexts, pageTitles } from 'data/ui-texts';
import teachersGuide from 'assets/pdfs/lærervejledning.pdf';
import PdfLink from 'components/ui/pdf-link/pdf-link';
import Area from 'components/ui/area/area';

const FinalArea = ({handleGoToGame, handleGoToGameStep, gameCode, game}) => {
	// Getting voting information for each area
	const votedActions1 = game.votedActions.filter((votedAction) => {
		return votedAction.area === 'area1';
	});
	const votedActions2 = game.votedActions.filter((votedAction) => {
		return votedAction.area === 'area2';
	});
	const votedActions3 = game.votedActions.filter((votedAction) => {
		return votedAction.area === 'area3';
	});

	return (
		<div className="FinalArea">
			{/* Header */}
			<div className='FinalArea-header'>
				<div className='FinalArea-title'>
					<span>{pageTitles.finalAreaTitle}</span>
				</div>
				<div className='FinalArea-subTitle'>
					<div className='FinalArea-subTitleText' dangerouslySetInnerHTML={{
						__html: pageTitles.finalAreaSubTitle}}/>
				</div>
			</div>

			<div className='FinalArea-backgroundWrapper'>
				<Area areaId={'area1'} position='allAreas'/>
				<Area areaId={'area2'} position='allAreas'/>
				<Area areaId={'area3'} position='allAreas'/>
			</div>

			<div className='FinalArea-backgroundWrapper'>
				<Area votedActions={votedActions1} areaId={'area1'} position='allAreas'/>
				<Area votedActions={votedActions2} areaId={'area2'} position='allAreas'/>
				<Area votedActions={votedActions3} areaId={'area3'} position='allAreas'/>
			</div>
			
			<div className='FinalArea-navigation'>
				<div className='FinalArea-navigationButtons'>
					<PdfLink link={teachersGuide} text={generalUiTexts.teachersGuide} styleClass='dark'/>
					<div>
						<Button
							text={generalUiTexts.lobby}
							classes={['lobby']}
							onClick={() => {handleGoToGame();}}
						/>
						{appConfig.env !== 'production' ? <Button
							text={generalUiTexts.back}
							onClick={() => {handleGoToGameStep('prev');}}
						/> : ''}
					</div>
				</div>
				
				<GameCode className='FinalArea-gameCode' gameCode={gameCode}/>

				<NextPageButton handleGoToGameStep={() => {handleGoToGame();}} text={continueButtonTexts.finish}/>
			</div>
		</div>
	);
};

FinalArea.propTypes = {
	handleGoToGame: PropTypes.func.isRequired,
	handleGoToGameStep: PropTypes.func.isRequired,
	gameCode: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
};

export default FinalArea;