import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './vote-overview.scss';
import Button from 'components/ui/button/button';
import { getDebateData } from 'helpers/area-data-helper';
import { voteTexts, pageTitles, continueButtonTexts, generalUiTexts, popupsData } from 'data/ui-texts';
import Popup from 'components/ui/popup/popup';
import GameCode from 'components/ui/game-code/game-code';
import Area from 'components/ui/area/area';
import { rules } from 'data/debate-data';
import appConfig from 'config/app.config';
import PdfLink from 'components/ui/pdf-link/pdf-link';
import teachersGuide from 'assets/pdfs/lærervejledning.pdf';

const VoteOverview = ({handleGoToGame, handleGoToGameStep, gameStepData, gameCode, groups}) => {
	const debateData = getDebateData(gameStepData.area);
	const [showPopup, setShowPopup] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const [popupMessage, setPopupMessage] = useState('');

	const getGroupVotesForArea = (areaId) => {
		const votes = [];

		groups.forEach((group) => {
			if (!group.votes) {
				return;
			}

			const vote = group.votes.find((vote) => {
				return vote.category === areaId && vote.area === gameStepData.area;
			});

			if (vote) {
				votes.push(group.index);
			}
		});

		return votes;
	};

	const handleGoToNextStep = () => {
		const groupsVotedCount = checkIfAllGroupsVoted();
		const totalVotes = groups.length * debateData.subArea.length;

		// All groups have voted, no need for a warning
		if (groupsVotedCount === totalVotes) {
			handleGoToGameStep('next');
		} else if (groupsVotedCount === 0) {
			setShowWarning(true);
			setPopupMessage(voteTexts.noGroup);
			// handle that no group has voted and facilitator clicked for next page
		} else if (groupsVotedCount < totalVotes) {
			setShowWarning(true);
			setPopupMessage(voteTexts.notAllGroups);
			// Warn facilitator that some groups have not voted yet.
		}
	};

	const checkIfAllGroupsVoted = () => {
		let count = 0;
		
		groups.forEach((group) => {
			if (group.votes) {
				count += group.votes.filter((vote) => {return vote.area === debateData.id;}).length;
			}
		});

		return count;
	};


	return (
		<div className="VoteOverview">
			{/* Area background svg */}
			<Area areaId={gameStepData.area}/>

			{showPopup && <Popup
				title={generalUiTexts.theRules}
				message={rules}
				messageClass={'rules'}
				cancelButtonText={generalUiTexts.ok}
				onContinueButtonClick={() => {}}
				onCancelButtonClick={() => {setShowPopup(false);}}
				showContinue={false}
				textAlign='left'
			/>}

			{showWarning && <Popup
				message={popupMessage}
				cancelButtonText={popupsData.voteOverviewCancel}
				onContinueButtonClick={() => {handleGoToGameStep('next');}}
				onCancelButtonClick={() => {setShowWarning(false);}}
			/>}

			<div className='VoteOverview-wrapper'>
				<div className='VoteOverview-subAreas'>
					{debateData.subArea.map((area, index) => {
						const areaVotes = getGroupVotesForArea(area.id);

						return (
							<div key={index} className='VoteOverview-subAreaVotes'>
								<div className='VoteOverview-subAreaBox'>
									<span>{area.title}</span>
								</div>
								<div className='VoteOverview-groups'>
									{groups.map((group, index) => {
										let groupHasVotedHere = areaVotes.findIndex((vote) => {
											return vote === group.index;
										}) !== -1;

										let groupClassName = 'VoteOverview-group groupBackground' + group.index + 
											' smallGroupLogo' + group.index;

										groupClassName += groupHasVotedHere ? ' active' : '';

										return (
											<div key={index} 
												className={groupClassName}>

											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
				
				<div className='VoteOverview-title'>
					<span>{pageTitles.voteOverviewTitle}</span>
				</div>

				<div className='VoteOverview-spacer'/>
			</div>

			{/* Contains gamecode and navigation buttons */}
			<div className='VoteOverview-navigation'>
				<div className='VoteOverview-navigationButtons'>
					<PdfLink link={teachersGuide} text={generalUiTexts.teachersGuide} styleClass='dark'/>
					<div>
						<Button
							text={generalUiTexts.lobby}
							classes={['lobby']}
							onClick={() => {handleGoToGame();}}
						/>
						{appConfig.env !== 'production' ? <Button
							text={generalUiTexts.back}
							onClick={() => {handleGoToGameStep('prev');}}
						/> : ''}
					</div>
				</div>

				<GameCode className='VoteOverview-gameCode' gameCode={gameCode}/>

				<div className='VoteOverview-continueButton'>
					<Button
						text={continueButtonTexts.voteOverview}
						classes={['continue']}
						onClick={() => {handleGoToNextStep();}}
					/>
					<div className='VoteOverview-arrow'/>
				</div>
			</div>
		</div>
	);
};

VoteOverview.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	gameStepData: PropTypes.object.isRequired,
	gameCode: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
};

export default VoteOverview;