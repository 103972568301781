import React, {useState} from 'react';
import './app.scss';
import firebaseInit from 'firebase-init';
import { useEffect } from 'react';
import LoginController from 'components/login/login-controller';
import FrontPage from './frontpage/frontpage';
import ImageLoader from './image-loader/image-loader';

function App() {
	const [gameStarted, startGame] = useState(false);

	useEffect(() => {
		/* Initialize firebase */
		firebaseInit();
	}, []);

	return (
		<div className="App">
			{gameStarted ? 
				<LoginController /> : <FrontPage startGame={() => {startGame(true);}}/>
			}
			<ImageLoader/>
		</div>
	);
}

export default App;
