import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './game-intro.scss';
import Button from 'components/ui/button/button';
import NextPageButton from 'components/ui/next-page-button/next-page-button';
import Popup from 'components/ui/popup/popup';
import CloudEngine from 'components/cloud-engine/cloud-engine';
import Group from 'components/ui/group-box/group-box';
import { infoTabs } from 'data/game-intro-data';
import { rules } from 'data/debate-data';
import { groupData } from 'data/group-data';
import { continueButtonTexts, generalUiTexts, loginUiTexts, pageUiTexts } from 'data/ui-texts';
import PdfLink from 'components/ui/pdf-link/pdf-link';
import teachersGuide from 'assets/pdfs/lærervejledning.pdf';
import VideoPopup from 'components/ui/video-popup/video-popup';

const GameIntro = ({handleGoToGame, handleGoToGameStep, gameCode, groups}) => {
	let url = window.location.href.split('/')[2];
	let gameCodeText = loginUiTexts.loginInstructions.replace('%URL%', url).replace('%gameCode%', gameCode);

	const [currentOpenTabIndex, setCurrentOpenTabIndex] = useState(null);
	const [showPopup, setShowPopup] = useState(false);
	const [showGroups, setShowGroups] = useState(false);
	const [showPresentations, setShowPresentations] = useState(false);
	const [continueButtonText, setContinueButtonText] = useState(continueButtonTexts.studentLogin);

	const [openedVideo, setOpenedVideo] = useState(null);
	const [clickedGroupId, setClickedGroupId] = useState(null);

	const openTab = (index) => {
		// if already current index, we close the tab instead
		if (index === currentOpenTabIndex) {
			setCurrentOpenTabIndex(null);
		} else {
			setCurrentOpenTabIndex(index);
		}
	};

	/**
	 * Handles next button page press. Switches between stages before moving to next game step.
	 */
	const nextPageButtonPressed = () => {
		// If presentations are showing, then we have already pressed next page once;
		if (showPresentations) {
			handleGoToGameStep('next');
		} else if (showGroups) { 
			setShowPresentations(true);
			setContinueButtonText(continueButtonTexts.continueRound + ' 1');
		} else {
			setShowGroups(true);
			setContinueButtonText(continueButtonTexts.continueLobby);
		}
	};

	/**
	 * Toggles group video
	 */
	const toggleGroupVideo = (groupId) => {
		const group = groupData.find((data) => {
			return data.id === groupId;
		});

		if (group) {
			if (openedVideo === group.video) {
				setClickedGroupId(null);
				setOpenedVideo(null);
			} else {
				setClickedGroupId(groupId);
				setOpenedVideo(group.video);
			}
		}
	};

	return (
		<div className="GameIntro">
			<CloudEngine/>
			{showPopup && <Popup
				title={generalUiTexts.theRules}
				message={rules}
				messageClass={'rules'}
				cancelButtonText={generalUiTexts.ok}
				onContinueButtonClick={() => {}}
				onCancelButtonClick={() => {setShowPopup(false);}}
				showContinue={false}
				textAlign='left'
			/>}
			{openedVideo && 
				<VideoPopup
					videoLink={openedVideo}
					closeVideo={() => {setOpenedVideo(null); setClickedGroupId(null);}}
				/>
			}
			<div className={'GameIntro-pageWrapper ' + (showGroups ? 'groupsShown' : 'initialPosition')}>
				<div className='GameIntro-contentWrapper'>
					{/* Information tabs about the game */}
					<div className='GameIntro-infoTabs'>
						{infoTabs.map((infoTab, index) => {
							const isActive = index === currentOpenTabIndex;

							return (
								<div key={index} className='GameIntro-infoTabWrapper'>
									<div className={'GameIntro-infoTab castShadow ' + (isActive ? ' active' : '')}
										onClick={() => {openTab(index);}}>
										<span>{infoTab.title}</span>
									</div>
									<div className={'GameIntro-infoTabArrow ' + (isActive ? ' active' : '')}/>
									<div className={'GameIntro-expandable ' + (isActive ? ' active' : '')}
										dangerouslySetInnerHTML={{__html: infoTab.text}}
									/>
								</div>
							);
						})}
					</div>

					{/* Title */}
					<div className='GameIntro-titleWrapper'>
						<div className='GameIntro-logo'/>
					</div>

					<div className='GameIntro-rulesButton'>
					</div>
				</div>

				<div className='Lobby-groupsWrapper'>
					{/* Groups first row */}
					<div className='Lobby-groups'>
						{groups === undefined ? '' : groups.map((group, index) => {
							let groupName = groupData.find((data) => {
								return data.id === group.index;
							}).name;

							return (
								<div key={index} className={'Lobby-group' + (showGroups ? '' : ' inactive')}>
									<Group
										key={index} 
										group={group} 
										groupName={groupName} 
										showReadyButton={true}
										fadeReadyButton={!showPresentations}
										onClickMethod={showPresentations ? () => {
											toggleGroupVideo(group.index);
										} : null}
										clicked={clickedGroupId === group.index}
									/>
								</div>
							);
						})}
					</div>
				</div>

				<div className='GameIntro-navigation'>
					<div className='GameIntro-navigationButtons'>
						<PdfLink link={teachersGuide} text={generalUiTexts.teachersGuide} styleClass='dark'/>
						<Button
							text={generalUiTexts.lobby}
							classes={['lobby']}
							onClick={() => {handleGoToGame();}}
						/>
					</div>

					<div className={showGroups ? '' : 'inactive'}>
						{showPresentations ? 
							<div className='GameIntro-presentationWrapper'>
								<div className='GameIntro-presentationIcon'/>
								<div className='GameIntro-presentationText'
									dangerouslySetInnerHTML={{__html: pageUiTexts.clickToPresent}}/>
							</div>
							:
							<div className='GameIntro-gameCodeWrapper'>
								<div className='GameIntro-gameCodeIcon'/>
								<div className='GameIntro-gameCode'
									dangerouslySetInnerHTML={{__html: gameCodeText}}/>
							</div>
						}
					</div>

					<NextPageButton handleGoToGameStep={() => {nextPageButtonPressed();}} text={continueButtonText}/>
				</div>
			</div>
		</div>
	);
};

GameIntro.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	gameCode: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
};

export default GameIntro;