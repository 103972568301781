import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {settingsUiTexts, generalUiTexts} from 'data/ui-texts';
import DeleteGamePopup from './delete-game-popup';
import './settings.scss';
import Button from 'components/ui/button/button';
import Area from 'components/ui/area/area';
import CloudEngine from 'components/cloud-engine/cloud-engine';
import { rules } from 'data/debate-data';
import Popup from 'components/ui/popup/popup';
import Gruppeskilte from 'assets/pdfs/Gruppeskilte.pdf';
import TeachersGuide from 'assets/pdfs/lærervejledning.pdf';
import PdfLink from 'components/ui/pdf-link/pdf-link';
import UsersPopup from './users-popup';

const Settings = (props) => {
	const {
		showDeleteGamePopup,
		isDeletingGame,
		gameId,
		currentGame,
		userData,
		users,
		toggleDeleteGamePopup,
		handleCreateGame,
		handleDeleteGame,
		handleGoToGame,
		handleLogout,
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [isShowingMaterials, setIsShowingMaterials] = useState(false);
	const [showUsersPopup, setShowUsersPopup] = useState(false);
	let timeOut = null;


	const handleStartNewGame = () => {
		// If a game already exists, popup is opened warning about game deletion
		if (currentGame) {
			toggleDeleteGamePopup(currentGame.id);
			return;
		}
		createNewGame();
	};

	const createNewGame = () => {
		setIsLoading(true);
		// We make a new game, and start it upon success
		handleCreateGame('new Game').then(
			(success) => {
				// Success
				setIsLoading(false);
				handleGoToGame(success.gameId);
			},
			(rejection) => {
				console.error('Failed to create new game.');
			}
		);
	};

	const continueGame = () => {
		setIsLoading(true);
		timeOut = setTimeout(() => {
			handleGoToGame(currentGame.id);
		}, 100);
	};

	// Save button random timeout effect
	useEffect(() => {
		return () => {
			if (timeOut) {
				clearTimeout(timeOut);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Toggles between showing material links and lobby buttons
	const toggleShowMaterials = () => {
		setIsShowingMaterials(!isShowingMaterials);
	};

	

	return (
		<div className="Settings">
			{showPopup && <Popup
				title={generalUiTexts.theRules}
				message={rules}
				messageClass={'rules'}
				cancelButtonText={generalUiTexts.ok}
				onContinueButtonClick={() => {}}
				onCancelButtonClick={() => {setShowPopup(false);}}
				showContinue={false}
				textAlign='left'
			/>}

			{/* Area background svg */}
			<Area position='bottom' areaId={'area1'}/>

			{/* Random Cloud generator */}
			<div className='cloudEngine'><CloudEngine /></div>

			<div className='Settings-titleWrapper'>
				<div className="Settings-title"><span>{generalUiTexts.title}</span></div>
			</div>

			<div className='Settings-dialogueWrapper'>
				<div className='Settings-gameWrapper'>
					{isShowingMaterials ?
						<div className='Settings-game'>
							<div className='Settings-gameTitle'><span>{settingsUiTexts.downloadMaterials}</span></div>
							<div className='Settings-gamePdfLinks'>
								<PdfLink link={Gruppeskilte} text={settingsUiTexts.tablePrintout} styleClass='light'/>
								<PdfLink link={TeachersGuide} text={generalUiTexts.teachersGuide} styleClass='light'/>
							</div>
						</div> 
						:
						<div className='Settings-game'>
							<div className='Settings-gameTitle'><span>{settingsUiTexts.startGame}</span></div>
							{/* New Game */}
							<div className={'Settings-newGameButton' + (currentGame ? '' : ' hasMargin')}>
								<Button
									isLoading={isLoading}
									isDisabled={isLoading}
									text={settingsUiTexts.createGame}
									classes={['play']}
									onClick={() => {handleStartNewGame();}}
								/>
							</div>
							{/* Start game */}
							{currentGame &&
								<div className='Settings-startGameButton'>
									<Button
										isLoading={isLoading}
										isDisabled={isLoading}
										text={settingsUiTexts.play}
										classes={['play']}
										onClick={() => {if (currentGame) continueGame();}}
									/>
								</div>
							}
							{/* Materials */}
							<div className='Settings-materialsButton'>
								<Button
									isLoading={isLoading}
									isDisabled={isLoading}
									text={settingsUiTexts.materials}
									classes={['play']}
									onClick={() => {toggleShowMaterials();}}
								/>
							</div>
							{userData.role === 'admin' && <div className="Settings-usersBtn">
								<Button
									text={generalUiTexts.showUsers}
									classes={['play']}
									onClick={() => {setShowUsersPopup(true);}}
								/>
							</div>}
						</div>
					}
				</div>
			</div>

			{isShowingMaterials ?
				<div className='Settings-navigation'>
					<Button
						text={generalUiTexts.lobby}
						classes={['lobby']}
						onClick={() => {toggleShowMaterials();}}
					/>
				</div>
				:
				<div className='Settings-navigation'>
					<Button
						text={generalUiTexts.logout}
						classes={['logout']}
						onClick={() => {handleLogout();}}
					/>
				</div>
			}

			{/* Delete game popup */}
			{(showDeleteGamePopup && gameId) && 
				<DeleteGamePopup 
					isDeletingGame={isDeletingGame}
					gameId={gameId}
					toggleDeleteGamePopup={toggleDeleteGamePopup}
					handleDeleteGame={() => {handleDeleteGame(gameId); createNewGame();}}
				/>}

			{showUsersPopup &&
				<UsersPopup users={users} setShowUsersPopup={setShowUsersPopup} />}

			<div className='Settings-funds'>
				<span>{generalUiTexts.fundedBy}</span>
				<div className='Settings-nordeaFund'/>
				<div className='Settings-openairFund'/>
				<div className='Settings-juneFund'/>
			</div>
		</div>
	);
};

Settings.propTypes = {
	showDeleteGamePopup: PropTypes.bool.isRequired,
	isDeletingGame: PropTypes.bool.isRequired,
	gameId: PropTypes.string,
	currentGame: PropTypes.object,
	userData: PropTypes.object,
	users: PropTypes.array.isRequired,
	toggleDeleteGamePopup: PropTypes.func.isRequired,
	handleCreateGame: PropTypes.func.isRequired,
	handleDeleteGame: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Settings;
