import React from 'react';
import PropTypes from 'prop-types';
import './group-info.scss';
import { groupData } from 'data/group-data';
import GroupGoals from '../group-goals/group-goals';
import { getGroupGameTotal } from 'helpers/group-points-helper';
import { pageUiTexts } from 'data/ui-texts';
import { pointDescription, pointThresholds } from 'data/debate-data';

const GroupInfo = ({group, closePopup = () => {}, closeable = true, game}) => {
	// Find current group from list
	const currentGroup = groupData.find((g) => {return g.id === group.index;});
	const groupLogoClass = 'GroupInfo-currentGroupLogo groupBackground' + group.index + ' groupLogo' + group.index;

	// Get current group points
	const groupPoints = getGroupGameTotal(group.index, game);

	return (
		<div className='GroupInfo'>
			<div className={'GroupInfo-currentGroup castShadow groupColor' + group.index}>
				<div className='GroupInfo-currentGroupInfo'>
					<div className={groupLogoClass}>
						{closeable ?
							<div
								className='GroupGoals-closeIcon'
								onClick={() => {
									closePopup();
								}}
							/> : ''
						}
						<div className='GroupInfo-groupName'>
							<span>{currentGroup.name}</span>
						</div>
					</div>

					<div className='GroupInfo-group'>
						<GroupGoals group={currentGroup}/>
					</div>
				</div>
				<div className='GroupInfo-groupPoints'>
					<div className={'GroupInfo-groupPointsHeader groupBackground' + group.index}>
						<div className='GroupInfo-groupPointsTitle'>
							<span>{pageUiTexts.ourPoints}</span>
						</div>
						<div className='GroupInfo-groupPointsTotal'>
							<span>{groupPoints}</span>
						</div>
					</div>

					<div className='GroupInfo-groupPointsBody'>
						<div className='GroupInfo-groupPointsDescription'>
							<span>{pointDescription}</span>
						</div>

						<div className='GroupInfo-groupPointsTrophyWrapper'>
							{pointThresholds.map((pointThreshold, index) => {
								return (
									<div key={index} className={'GroupInfo-groupPointsTrophyContainer groupBackground' 
										+ group.index}>
										<div className='GroupInfo-groupPointsThreshold'>
											<span>{pointThreshold.limit + ' point ='}</span>
										</div>
										<div className={'GroupInfo-groupPointsTrophy ' + pointThreshold.level}/>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

GroupInfo.propTypes = {
	group: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
	closePopup: PropTypes.func,
	closeable: PropTypes.bool,
};

export default GroupInfo;