import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import './users-popup.scss';

const UsersPopup = ({users, setShowUsersPopup}) => {
	return (
		<div className="UsersPopup" onClick={() => {setShowUsersPopup(false);}}>
			<div className="UsersPopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="UsersPopup-header">
					<div className="UsersPopup-title">
						{generalUiTexts.users} ({users.length})
					</div>
					<div className="UsersPopup-closeBtn" onClick={() => {setShowUsersPopup(false);}} />
				</div>
				<div className="UsersPopup-body">
					<div className="UsersPopup-users">
						{users.map((user, index) => {
							if (!user.email) return null;
							return (
								<span key={index}>
									{user.email + (index < users.length - 1 ? ', ' : '')} 
								</span>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

UsersPopup.propTypes = {
	users: PropTypes.array.isRequired,
	setShowUsersPopup: PropTypes.func.isRequired,
};

export default UsersPopup;
