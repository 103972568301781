import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './vote.scss';
import { voteTexts, generalUiTexts, pageUiTexts } from 'data/ui-texts';
import { voteData } from 'data/debate-data';
import GroupSidebar from 'components/ui/group-sidebar/group-sidebar';
import GroupHint from 'components/ui/group-hint/group-hint';
import Popup from 'components/ui/popup/popup';
import Area from 'components/ui/area/area';
import Button from 'components/ui/button/button';

const Vote = ({handleLogout, debateData, gameStepData, updateVote, removeFromVote, currentVotes, group, game}) => {
	let timeout = useRef(null);
	const votes = voteData;
	const [hasSaved, setHasSaved] = useState(false); // Has saved once
	const [saving, setSaving] = useState(false);
	const [dragEnded, setDragEnded] = useState(false);
	const [selectedVote, setSelectedVote] = useState(0);
	const [selectedAction, setSelectedAction] = useState(0);
	const [selectedVoteCategory, setSelectedVoteCategory] = useState('');

	const [showPopup, setShowPopup] = useState(true);

	// Updates vote in database, and clears current votes
	useEffect(() => {
		if (selectedVote !== 0 && selectedAction !== 0) {
			const newVote = {category: selectedVoteCategory, point: selectedVote, action: selectedAction};

			updateVote(newVote);
			setSelectedVote(0);
			setSelectedAction(0);
			setSelectedVoteCategory('');
			setSaving(true);
		} else if (selectedVote !== 0 && dragEnded) {
			const voteToRemove = {category: selectedVoteCategory, point: selectedVote, action: selectedAction};
			setSaving(true);
			removeFromVote(voteToRemove);
		}

	}, [selectedVote, selectedAction, selectedVoteCategory, updateVote, dragEnded, removeFromVote]);
	
	// Save button random timeout effect
	useEffect(() => {
		if (saving) {
			if (!hasSaved) setHasSaved(true);

			let timeoutLength = (Math.random() * 2 + 1) * 1000; // Random number from 1000 - 3000

			if (timeout.current !== null) {
				clearTimeout(timeout.current);
			}

			timeout.current = setTimeout(() => {
				setSaving(false);
			}, timeoutLength);
		}

		return () => {clearTimeout(timeout.current);};
	}, [saving, hasSaved]);

	const onDragOver = (event) => {
		event.preventDefault();
	};

	const onDrop = (actionId, areaId) => {
		setSelectedAction(actionId); 
		setSelectedVoteCategory(areaId);
	};

	return (
		<div className="Vote">
			{/* Area background svg */}
			<Area areaId={gameStepData.area}/>
			
			{/* Group Information Sidebar */}
			<div className='Vote-groupInfoBar'>
				<GroupSidebar showGroups={false} game={game} handleLogout={()=>{handleLogout();}} group={group}/>
				<div className='Vote-logoutButton'>
					<Button
						text={generalUiTexts.logout}
						classes={['logout']}
						onClick={() => {handleLogout();}}
					/>
				</div>
			</div>
			{showPopup && <Popup
				title={pageUiTexts.votingStarted}
				message={pageUiTexts.howToVote}
				cancelButtonText={generalUiTexts.ok}
				onContinueButtonClick={() => {}}
				onCancelButtonClick={() => {setShowPopup(false);}}
				showContinue={false}
				textAlign='left'
			/>}

			<div className='Vote-contentWrapper'>
				<div className='Vote-bodyWrapper'>
					<div className='Vote-subAreas'>
						{debateData.subArea.map((area, index, arr) => {
							return (
								<div key={index} className='Vote-subject castShadow'>
									<div className='Vote-subjectTitle'>
										{area.title}
									</div>
									{area.actions.map((action, index) => {
										let actionBoxClassName = 'Vote-actionBox';
										let currentPoints = '';

										let voteIndex = -1;
										if (currentVotes !== undefined) {
											voteIndex = currentVotes.findIndex((vote) => {
												// Vote.action is not zero indexed
												return vote.category === area.id && (vote.action - 1) === index;
											});
										}
										
										if (voteIndex !== -1) {
											actionBoxClassName += 
												' castShadow whiteBorder groupBackground' + group.index;
											if (currentVotes[voteIndex].action === action.id) {
												currentPoints = currentVotes[voteIndex].point;
											}
										} else {
											actionBoxClassName += ' noVote'; 
										}

										let actionClassName = 'Vote-action';
										let isNotLast = (arr.length - 1) !== index;
										actionClassName += isNotLast ? ' hasBorder' : '';

										let isDraggable = currentPoints !== '' ? 'true' : 'false';

										return (
											<div key={index} className={actionClassName}>
												<div className='Vote-actionWrapper'>
													<div className='Vote-actionIdWrapper'>
														<div className='Vote-actionId'>
															<span>{action.title}</span>
														</div>
														{action.isExpensive ? <div className='Vote-isExpensive'/> : ''}
													</div>
													<div className='Vote-actionDescription'>
														<span>{action.description}</span>
													</div>
												</div>
												<div 
													className={actionBoxClassName}
													onDragOver={onDragOver}
													onDrop={() => {onDrop(action.id, area.id);}}
													draggable={isDraggable}
													onDragStart={() => {
														if (currentPoints !== '') {
															setSelectedVote(currentPoints);
														}
													}}
													onDragEnd={() => {
														setDragEnded(true);
													}}
												>
													<div className='Vote-actionBoxText'>{currentPoints}</div>
												</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
					<div className='Vote-votes'>
						<div className='Vote-voteDialogue'>
							<div className={'Vote-votesTitle castShadow groupBackground' + group.index}>
								{voteTexts.yourInfluence}
							</div>

							<div className='Vote-votesWrapper castShadow'>
								{votes.map((vote, index) => {
									let className = 'Vote-vote groupBackground' + group.index;
									
									let isUsed = currentVotes.findIndex((currentVote) => {
										return currentVote.point === vote && gameStepData.area === currentVote.area;
									}) !== -1;

									className += isUsed ? ' isUsed' : ' castShadow';

									return (
										<div
											key={index}
											draggable="true"
											className={className}
											onDragStart={() => {setSelectedVote(vote);}}
										>
											<div className='Vote-voteText'>{vote}</div>
										</div>
									);
								})}
								<div className='Vote-voteIndicatorIcon'/>
							</div>
						</div>

						{/* Indicator indicating that the votes are saved */}
						<div className='Vote-saveNotification'>
							<div className='Vote-saving'>
								<div className='Vote-savingTextWrapper'>
									<div className={'Vote-savingText' + (saving ? ' active' : '')}>
										{voteTexts.saving}
									</div>
									<div className={'Vote-savedText' + (saving || !hasSaved ? '' : ' active')}>
										{voteTexts.saved}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<GroupHint group={group} gameStepData={gameStepData}/>
		</div>
	);
};

Vote.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	updateVote: PropTypes.func.isRequired,
	removeFromVote: PropTypes.func.isRequired,
	debateData: PropTypes.object.isRequired,
	currentVotes: PropTypes.array.isRequired,
	gameStepData: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
};

export default Vote;