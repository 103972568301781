import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './group-hint.scss';
import { getDebateGroupHint } from 'helpers/area-data-helper';
import { pageUiTexts } from 'data/ui-texts';
import { groupData } from 'data/group-data';

const GroupHint = ({gameStepData, group, startOpen = false}) => {
	const groupHints = getDebateGroupHint(gameStepData.area, group.index);
	
	// Find current group from list
	const currentGroup = groupData.find((g) => {return g.id === group.index;});

	// Open hints panel
	const [hintPanelIsOpen, setHintPanelIsOpen] = useState(startOpen);

	return (
		<div className='GroupHint'>
			<div className='GroupHint-groupHint'>
				<div className='GroupHint-groupHintBox'>
					<div className={'GroupHint-groupHintTitle groupBackground' + currentGroup.id}>
						<span>{pageUiTexts.groupHint}</span>
						<div 
							className={hintPanelIsOpen ? 
								'GroupHint-closeIcon' : 'GroupHint-expandIconUp'}
							onClick={() => {setHintPanelIsOpen(!hintPanelIsOpen);}}
						/>
					</div>

					<div className={'GroupHint-groupHintText' + (hintPanelIsOpen ? ' active' : '')}>
						<ul>
							{groupHints.map((hint, index) => {
								return (
									<div key={'hint' + index} className='GroupHint-groupHintLine'>
										<li>{hint}</li>
									</div>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

GroupHint.propTypes = {
	gameStepData: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	startOpen: PropTypes.bool,
};

export default GroupHint;