import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { errorUiTexts } from 'data/ui-texts';
import LoginFacilitator from './login-facilitator';

class LoginFacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
			password: null,
			feedback: null,
			isLoading: false
		};
	}

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	}

	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		if (event) event.preventDefault();
		this.setState({isLoading: true}, () => {
			/* Error: missing fields */
			let email = this.state.email;
			let password = this.state.password;
			if (!email || !password) {
				
				this.setState({
					isLoading: false,
					feedback: errorUiTexts.missingFields
				});
				return;
			}
			/* Sign in */
			firebase.auth().signInWithEmailAndPassword(email, password)
				.catch((error) => {
					let errorMsg = errorUiTexts.unknownError;
					if (error.code === 'auth/invalid-email') errorMsg = errorUiTexts.invalidEmail;
					if (error.code === 'auth/user-not-found') errorMsg = errorUiTexts.emailNotFound;
					if (error.code === 'auth/wrong-password') errorMsg = errorUiTexts.invalidEmailPassword;
					this.setState({feedback: errorMsg, isLoading: false});
				});
		});
	}


	render = () => {
		return (
			<LoginFacilitator
				handleLogin={this.handleLogin}
				handleInput={this.handleInput}
				setFacilitatorBoxType={this.props.setFacilitatorBoxType}
				email={this.state.email}
				password={this.state.password}
				feedback={this.state.feedback}
				isLoading={this.state.isLoading}
			/>
		);
	 }
}

LoginFacilitatorController.propTypes = {
	setFacilitatorBoxType: PropTypes.func.isRequired,
};

export default LoginFacilitatorController;
