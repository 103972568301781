import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';
import './video-popup.scss';


const VideoPopup = ({videoLink, closeVideo}) => {
	const [hasVideoEnded, setHasVideoEnded] = useState(false);

	useEffect(() => {
		if (hasVideoEnded) {
			closeVideo();
		}
	}, [hasVideoEnded, closeVideo]);

	return (
		<div className="VideoPopup">
			<div className='VideoPopup-videoCanvas'>
				<div className='VideoPopup-videoWrapper'>
					<YouTube
						className={'VideoPopup-video'} 
						videoId={videoLink} 
						onPlay={() => {setHasVideoEnded(false);}}
						onEnd={() => {setHasVideoEnded(true);}}
						opts={{
							height: '100%', 
							width: '100%', 
							playerVars: {
								controls: 1,
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

VideoPopup.propTypes = {
	videoLink: PropTypes.string.isRequired,
	closeVideo: PropTypes.func.isRequired,
};

export default VideoPopup;