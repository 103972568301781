import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/button/button';
import './attention.scss';
import { pageUiTexts, generalUiTexts } from 'data/ui-texts';
import Area from 'components/ui/area/area';
import CloudEngine from 'components/cloud-engine/cloud-engine';

const Attention = ({handleLogout, gameStepData, game}) => {
	let actions = [];
	
	if (game.votedActions) {
		actions = game.votedActions.filter((action) => {return action.area === gameStepData.area;});
	}

	return (
		<div className="Attention">
			<Area votedActions={actions} areaId={gameStepData.area}/>
			<CloudEngine/>

			<div className='Attention-textWrapper'>
				<div className='Attention-text'>
					<div className='Attention-eyeIcon'/>
					<span>{pageUiTexts.attention}</span>
				</div>
			</div>

			<div className='Attention-logoutButton'>
				<Button
					classes={['logout']}
					text={generalUiTexts.logout}
					onClick={() => {handleLogout();}}
				/>
			</div>
		</div>
	);
};

Attention.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	gameStepData: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
};

export default Attention;