const { pointTypes } = require("./debate-data");

const groupData = [
	{
		id: 1,
		name: 'Landmænd og skovejere',
		text: `Vi ejer meget af jorden i området.
		<br><br>Vi dyrker jorden - og vi vil gerne sikre, at vi kan blive ved med at producere korn, græs, kvæg, træ - og alt muligt andet på jorden.
		<br><br>Vi lejer også jord ud til jagt - og det er en vigtig indtægt.
		<br><br>Vi kan godt se, at der er behov for en grøn omstilling - og vi vil gerne være med til at finde nye veje og træffe de nødvendige beslutninger.`,
		pointScalas: [pointTypes[3].id, pointTypes[2].id, pointTypes[1].id], // Order corresponds to primary, secondary and tertiary
		video: 'nhyIJFxijgg',
	},
	{
		id: 2,
		name: 'Naturens venner',
		text: `Vi vil gerne have en mere vild natur - og øge områdets biodiversitet.
		<br><br>Der er behov for at give den vilde natur mere plads, så den kan blive mere robust og beskyttet mod klima-forandringer.
		<br><br>Det er behov for at skabe varieret natur og grønne korridorer, hvor dyr og planter kan vandre.
		<br><br>Det vil være til gavn for naturen - og for os mennesker.`,
		pointScalas: [pointTypes[4].id, pointTypes[0].id, pointTypes[2].id],
		video: 'YN16fNlWKcI',
	},
	{
		id: 3,
		name: 'Friluftsfolket',
		text: `Vi kan lide at vandre, køre mountainbike, tage på spejdertur, sejle kajak - ja dyrke friluftsliv.
		<br><br>Vi bor i området og vil gerne have adgang til naturen.
		<br><br>For os er det vigtigt, at naturen er skøn at være i.
		<br><br>Det vil være fint, hvis de nye tiltag kan skabe nye arbejdspladser og mere friluftsliv i området.`,
		pointScalas: [pointTypes[0].id, pointTypes[1].id, pointTypes[3].id],
		video: 'mHAwvwaRdCM',
	},
	{
		id: 4,
		name: 'Klimakæmperne',
		text: `Vi kæmper for klimaet og mod klima-forandringer.
		<br><br>Vi arbejder for en grøn omstilling, hvor vi udleder mindre CO2 - både som samfund og som privatpersoner.
		<br>Derfor vil vi gerne være med til at skabe produktion - bare det ikke udleder CO2.
		<br><br>Vi arbejder også for at sikre området mod klimaforandringer - fx mod oversvømmelse eller tørke.
		<br><br>For os er det også vigtigt med vild natur og høj biodiversitet.`,
		pointScalas: [pointTypes[2].id, pointTypes[4].id, pointTypes[3].id],
		video: 'ACZnqIrOk8c',
	},
	{
		id: 5,
		name: 'Jægerne og lystfiskerne',
		text: `Vi vil gerne kunne fiske og gå på jagt i naturen.
		<br><br>Det betyder også, at vi arbejder for en varieret natur, som har plads til vilde dyr.
		<br><br>For uden bæredygtige bestande, er der ingen mulighed for at fiske og jage. `,
		pointScalas: [pointTypes[1].id, pointTypes[4].id, pointTypes[0].id],
		video: 'LDponV0hugE',
	},
	{
		id: 6,
		name: 'Erhvervsgruppen',
		text: `Vi er de lokale erhvervsfolk.
		<br><br>For os er det vigtigt, at der er produktion og arbejdspladser i området.
		<br><br>Det kan være landbrug og skovbrug, men det kan også være turisme.
		<br>Derfor er det vigtigt for os, at området har en smuk og varieret natur.
		<br><br>Vi vil også gerne have et område, som er bæredygtigt i forhold til klimaforandringer.`,
		pointScalas: [pointTypes[3].id,  pointTypes[0].id,  pointTypes[2].id],
		video: '3OQE5c-ncMw',
	}
];

module.exports = {groupData};