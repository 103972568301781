import React from 'react';
import PropTypes from 'prop-types';
import './next-page-button.scss';
import Button from 'components/ui/button/button';

const NextPageButton = ({handleGoToGameStep, text, active = true}) => {
	return (
		<div className={'NextPageButton' + (active ? ' active' : '')}>
			<Button
				text={text}
				classes={['continue']}
				onClick={() => {handleGoToGameStep('next');}}
			/>
			<div className='NextPageButton-arrow'/>
		</div>
	);
};

NextPageButton.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
	active: PropTypes.bool,
};

export default NextPageButton;