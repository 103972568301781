import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './timer.scss';
import { pageUiTexts } from 'data/ui-texts';

const Timer = ({title = 'TITLE', countDown}) => {
	const [seconds, setSeconds] = useState(0);
	const [timerStarted, startTimer] = useState(false);
	const totalSeconds = 60 * countDown;
	const secondsDif = totalSeconds - seconds;
	const currentMinutes = Math.floor(secondsDif / 60);
	const currentSeconds = (secondsDif % 60).toLocaleString('da-DA', {minimumIntegerDigits: 2});

	useEffect(() => {
		if (timerStarted) {
			const interval = setInterval(() => {
				setSeconds((seconds) => {
					if (seconds === totalSeconds) {
						return seconds;
					}

					return seconds + 1;
				});
			}, 1000);
			return () => {clearInterval(interval);};
		}
	});

	return (
		<div className='Timer'>
			<div className='Timer-title'><span>{title}</span></div>
			<div className={'Timer-box' + (timerStarted ? ' started' : ' paused')} onClick={() => {startTimer(true);}}>
				<span>
					{timerStarted ? currentMinutes + ':' + currentSeconds : pageUiTexts.startTimer}
				</span>
			</div>
		</div>
	);
};

Timer.propTypes = {
	countDown: PropTypes.number.isRequired,
	title: PropTypes.string,
};

export default Timer;