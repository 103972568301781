let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let gamesDbName = 'games';
let groupsDbName = 'groups';
let gameUrl = 'localhost:8080';
let apiURL = 'http://localhost:8080/api/';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-natursyn-test.web.app';
	apiURL = 'https://cgl-natursyn-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-natursyn-demo2.web.app';
		apiURL = 'https://cgl-natursyn-demo2.web.app/api/';
		gamesDbName = 'games-demo';
		groupsDbName = 'groups-demo';
	}
}

if (env === 'production') {
	gameUrl = 'cgl-natursyn-production.web.app';
	apiURL = 'https://cgl-natursyn-production.web.app/api/';
}
let gameTitleMaxLength = 8;

const appConfig = {
	env: env,
	apiURL: apiURL,
	gamesDbName: gamesDbName,
	groupsDbName: groupsDbName,
	gameUrl: gameUrl,
	gameTitleMaxLength: gameTitleMaxLength,
};

export default appConfig;
