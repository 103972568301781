import React from 'react';
import PropTypes from 'prop-types';
import './game-code.scss';
import { pageUiTexts } from 'data/ui-texts';

const GameCode = ({gameCode}) => {
	let url = window.location.href.split('/')[2];

	return (
		<div className='GameCode'>
			<div className='GameCode-icon'/>
			<div className='GameCode-code'>
				<span>{'URL: ' + url}</span>
				<span>{pageUiTexts.gameCode + ' ' + gameCode}</span>
			</div>
		</div>
	);
};

GameCode.propTypes = {
	gameCode: PropTypes.string.isRequired,
};

export default GameCode;