import { debateData, debateGroupHints, pointTypes } from 'data/debate-data';

/**
 * Gets debate data for area
 * @param {string} areaId 
 */
export function getDebateData(areaId) {
	const data = debateData.find((data) => {
		return data.id === areaId;
	});

	return data;
}

/**
 * Gets round number for areaId
 * @param {string} areaId 
 * @returns {number} represents round number
 */
export function getRoundNumber(areaId) {
	const roundNumber = debateData.findIndex((data) => {
		return data.id === areaId;
	}) + 1;

	return roundNumber;
}

/**
 * Calculates total sum of possible points in area, per point type.
 * If area is null, returns sum of all possible points in all areas.
 * @param {string} areaId 
 * @returns {Array} array with sum of point total possible points for each point type in area
 */
export function getAreaMaxPoints(areaId) {
	let areaData;

	if (areaId !== null) {
		areaData = getDebateData(areaId);
	} else {
		let subArea = [];
		debateData.forEach((data) => {
			subArea = subArea.concat(data.subArea);
		});
		areaData = {subArea: subArea};
	}

	let acc = {point: 0, type: pointTypes[0].id};
	let fre = {point: 0, type: pointTypes[1].id};
	let eco = {point: 0, type: pointTypes[2].id};
	let pro = {point: 0, type: pointTypes[3].id};
	let bio = {point: 0, type: pointTypes[4].id};

	let accCurrentMax;
	let bioCurrentMax;
	let ecoCurrentMax;
	let freCurrentMax;
	let proCurrentMax;

	areaData.subArea.forEach((subArea) => {
		accCurrentMax = 0;
		freCurrentMax = 0;
		ecoCurrentMax = 0;
		proCurrentMax = 0;
		bioCurrentMax = 0;

		subArea.actions.forEach((action) => {
			action.value.forEach((value) => {
				switch (value.type) {
				case 'acc':
					if (accCurrentMax < value.point) accCurrentMax = value.point;
					break;
				case 'bio':
					if (bioCurrentMax < value.point) {
						bioCurrentMax = value.point;
					};  
					break;
				case 'eco':
					if (ecoCurrentMax < value.point) ecoCurrentMax = value.point;  
					break;
				case 'fre':
					if (freCurrentMax < value.point) freCurrentMax = value.point;  
					break;
				case 'pro':
					if (proCurrentMax < value.point) proCurrentMax = value.point;  
					break;
				default: console.error('No such point type: ' + value.type);
				}
			});
		});

		acc.point += accCurrentMax;
		bio.point += bioCurrentMax;
		eco.point += ecoCurrentMax;
		fre.point += freCurrentMax;
		pro.point += proCurrentMax;
	});

	const resultArray = [];
	resultArray.push(acc);
	resultArray.push(fre);
	resultArray.push(eco);
	resultArray.push(pro);
	resultArray.push(bio);

	return resultArray;
}

/**
 * Returns points for the given voted area.
 * Assumes list of voted actions are for one area.
 * @param {Array} votedActions list of voted actions for an area
 * @returns array of objects containing point data for each given action.
 */
export function getNewAreaPoints(votedActions) {
	let newPoints = [];
	const areaId = votedActions[0].area;
	const areaData = getDebateData(areaId).subArea;

	votedActions.forEach((action) => {
		const subArea = areaData.find((data) => {
			return data.id === action.category;
		});

		const pointData = subArea.actions.find((subAreaAction) => {
			return subAreaAction.id === action.action;
		});

		newPoints.push({category: action.category, action: action.action, points: pointData.value});
	});

	newPoints = newPoints.sort((a, b) => {
		return b.category - a.category;
	}).reverse();

	return newPoints;
}

/**
 * Gets the hints for the given group and area.
 * @param {int} areaId 
 * @param {int} groupId 
 * @returns 
 */
export function getDebateGroupHint(areaId, groupId) {
	const data = debateGroupHints.find((data) => {
		return data.groupId === groupId && data.areaId === areaId;
	});

	return data.hints;
}
