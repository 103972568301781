import React, { useState, useEffect } from 'react';
import './cloud-engine.scss';

const CloudEngine = () => {
	const [cloudArray, setCloudArray] = useState([]);

	let maxCloudDelay = 10;
	let cloudAmount = 5;

	let minCloudHeight = 30;
	let maxCloudHeight = 90;
	
	let minCloudDuration = 50;
	let maxCloudDuration = 120;

	let minCloudSize = 0.5;
	let maxCloudSize = 2;

	useEffect(() => {
		const newArray = [];

		for (let i = 0; i < cloudAmount; i++) {
			// Random number between minCloudHeight and maxCloudHeight
			const positionY = Math.random() * (maxCloudHeight - minCloudHeight) + minCloudHeight;
			const randomDelay = Math.random();

			// Random number between minCloudDuration and maxCloudDuration
			const randomDuration = Math.random() * (maxCloudDuration - minCloudDuration) + minCloudDuration;

			// Random number between minCloudSize and maxCloudSize
			const sizeScalar = Math.random() * (maxCloudSize - minCloudSize) + minCloudSize;

			newArray.push({
				animationDelay: randomDelay * maxCloudDelay + 's',
				animationDuration: randomDuration + 's',
				bottom: positionY + '%',
				transform: 'scale(' + sizeScalar + ')',
			});
		}

		setCloudArray(newArray);
	}, [
		maxCloudDelay, 
		cloudAmount, 
		minCloudDuration, 
		maxCloudDuration, 
		minCloudSize, 
		maxCloudSize, 
		minCloudHeight, 
		maxCloudHeight
	]);


	return (
		<div className="CloudEngine">
			{cloudArray ? cloudArray.map((cloud, index) => {
				return (
					<div key={index} className='CloudEngine-cloud'
						style={{
							animationDelay: cloud.animationDelay,
							animationDuration: cloud.animationDuration,
							bottom: cloud.bottom,
							transform: cloud.transform,
						}}/>
				);
			}) : ''}
		</div>
	);	
};

export default CloudEngine;