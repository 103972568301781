import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Vote from 'components/game-steps/student/vote/vote';
import { getDebateData } from 'helpers/area-data-helper';

class VoteController extends Component {
	/**
	 * Updates group with the current group vote
	 * @param {object} vote object containing details for the given vote 
	 */
	updateVote = (vote) => {
		const newVote = {
			area: this.props.gameStepData.area, 
			category: vote.category, 
			point: vote.point, 
			action: vote.action
		};
		let newVotes = this.props.group.votes === undefined ? [] : this.props.group.votes;

		if (newVotes.length !== 0) {
			// Check if point has already been used in any category
			const pointIndex = newVotes.findIndex((currentVote) => {
				return currentVote.point === vote.point && currentVote.area === this.props.gameStepData.area;
			});

			if (pointIndex !== -1) {
				newVotes.splice(pointIndex, 1);
			}

			// Check if vote has already been made for the given category
			const voteIndex = newVotes.findIndex((currentVote) => {
				return currentVote.category === vote.category && currentVote.area === this.props.gameStepData.area;
			});

			// If category has already been voted for, change vote to new vote
			if (voteIndex !== -1) {
				newVotes[voteIndex].point = vote.point;
				newVotes[voteIndex].action = vote.action;
				newVotes[voteIndex].area = this.props.gameStepData.area;
			} else {
				newVotes.push(newVote);
			}
		} else {
			newVotes.push(newVote);
		}

		this.props.updateGroup({votes: newVotes, areVotesSaved: true});
	}

	/**
	 * Removes specific vote from group
	 * @param {object} vote object containing details for the given vote 
	 */
	removeFromVote = (vote) => {
		if (this.props.group.votes === undefined || this.props.group.votes.length === 0) return;

		let currentVotes = this.props.group.votes;

		// filter point list, to remove point
		const newVotes = currentVotes.filter((currentVote) => {
			return currentVote.point !== vote.point && 
				currentVote.category !== vote.category && 
				currentVote.action !== vote.action;
		});

		this.props.updateGroup({votes: newVotes, areVotesSaved: true});
	}

	/**
	 * Render component
	 */
	render = () => {
		const debateData = getDebateData(this.props.gameStepData.area);
		let currentVotes = [];
		if (this.props.group.votes) {
			currentVotes = this.props.group.votes.filter((vote) => {
				return vote.area === this.props.gameStepData.area;
			});
		}

		return (
			<Vote
				handleLogout={this.props.handleLogout}
				debateData={debateData}
				updateVote={this.updateVote}
				removeFromVote={this.removeFromVote}
				currentVotes={currentVotes}
				gameStepData={this.props.gameStepData}
				group={this.props.group}
				game={this.props.game}
			/>
		);
	}
}

VoteController.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	gameStepData: PropTypes.object.isRequired,
	group: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
};

export default VoteController;