import React from 'react';
import PropTypes from 'prop-types';
import {pageUiTexts, loginUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import { groupData } from 'data/group-data';
import GroupBox from 'components/ui/group-box/group-box';

const LoginGroup = (props) => {
	const {
		isLoading,
		code,
		feedback,
		groupId,
		groups,
		handleInput,
		handleFindGame,
		handleGetLoginToken,
	} = props;

	/* Input game code */
	if (groups === null) {
		return (
			<div className="LoginGroup">
				<form className="Login-form" onSubmit={handleFindGame}>
					<div className="Login-header">
						<span>{loginUiTexts.studentLogin}</span>
						<div className='Login-inputWrapper'>
							<input
								name="code"
								type="text"
								placeholder={pageUiTexts.gameCodeNoColon} 
								autoComplete="section-grouplogin code"
								value={code ? code : ''}
								onChange={(event)=>{handleInput(event);}}
							/>
						</div>
					</div>
					<div className='Login-spacing'/>
					<Button
						isLoading={isLoading}
						classes={['login']}
						text={loginUiTexts.login} 
						onClick={handleFindGame} 
					/>
					<div className="Login-errorMessage">{feedback}</div>
					<div className='Login-gameCodeMessage'>
						{pageUiTexts.gameCodeInfo}
					</div>
				</form>
			</div>
		);
	}
	
	/* Select group */
	return (
		<div className="LoginChooseGroup">
			<div className='LoginChooseGroup-title'><span>{loginUiTexts.chooseGroup}</span></div>
			<div className='LoginChooseGroup-groupsWrapper'>
				{/* First group row */}
				<div className="Login-groups">
					{groups.slice(0, 3).map((group, index)=>{
						const groupIsLoading = (isLoading && group.id === groupId);
						const groupObject = groupData.find((data) => {
							return data.id === group.index;
						});

						return (
							<div key={index} className='Login-groupSelect'>
								<div className='Login-groupBox'>
									<GroupBox group={group} groupName={groupObject.name} showReadyButton={false}/>
								</div>

								<div className={'Login-groupSelectBtn'}>
									<Button
										isDisabled={isLoading}
										isLoading={groupIsLoading}
										classes={['login blue']}
										text={loginUiTexts.choose} 
										onClick={() => {handleGetLoginToken(group.id);}}
									/>
								</div>
							</div>
						);
					})}
				</div>
				{/* Second group row */}
				<div className="Login-groups">
					{groups.slice(3, 6).map((group, index)=>{
						const groupIsLoading = (isLoading && group.id === groupId);
						const groupObject = groupData.find((data) => {
							return data.id === group.index;
						});

						return (
							<div key={index} className='Login-groupSelect'>
								<div className='Login-groupBox'>
									<GroupBox group={group} groupName={groupObject.name} showReadyButton={false}/>
								</div>
								
								<div className={'Login-groupSelectBtn'}>
									<Button
										isDisabled={isLoading}
										isLoading={groupIsLoading}
										classes={['login blue']}
										text={loginUiTexts.choose} 
										onClick={() => {handleGetLoginToken(group.id);}}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

LoginGroup.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	code: PropTypes.any,
	feedback: PropTypes.string,
	groupId: PropTypes.string,
	groups: PropTypes.any,
	handleInput: PropTypes.func.isRequired,
	handleFindGame: PropTypes.func.isRequired,
	handleGetLoginToken: PropTypes.func.isRequired,
};

export default LoginGroup;