import React from 'react';
import PropTypes from 'prop-types';
import './pdf-link.scss';

const PdfLink = ({link, text, styleClass = ''}) => {

	return (
		<div className={'PdfLink ' + styleClass}>
			<a href={link} target = "_blank" rel="noreferrer">
				{text}
			</a>
		</div>
	);
};

PdfLink.propTypes = {
	link: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	styleClass: PropTypes.string,
};

export default PdfLink;