import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './cookie-consent.scss';

const CookieConsent = ({ handleClosePopup }) => {
	return (
		<div className="CookieConsent">
			<div className="CookieConsent-text">
				<span>{loginUiTexts.cookiesPopup.text  + ' '}</span>
				<a href="https://cphgamelab.dk/privatlivspolitik" target="_blank" rel="noreferrer">
					{loginUiTexts.cookiesPopup.more}
				</a>
			</div>
			<div className="CookieConsent-button">
				<Button 
					classes={['cookies']} 
					text={loginUiTexts.cookiesPopup.btn} 
					onClick={handleClosePopup}
				/>
			</div>
		</div>
	);	
};

CookieConsent.propTypes = {
	handleClosePopup: PropTypes.func.isRequired
};

export default CookieConsent;