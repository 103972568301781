import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import apiHelper from 'helpers/api-helper';
import Settings from './settings';

class SettingsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCreatingGame: false,
			isDeletingGame: false,
			showCreateGamePopup: false,
			showDeleteGamePopup: false,
			gameId: null,
			errMsg: null,
			users: []
		};
		this.unsubscribeUsers = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		if (this.props.userData.role === 'admin') {
			this.subscribeToUsers();
		}
	}

	subscribeToUsers = () => {
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection('users').onSnapshot((querySnapshot) => {
				let users = [];

				querySnapshot.forEach((doc) => {
					let data = doc.data();
					data.id = doc.id;
					users.push({id: doc.id, ...doc.data()});
				});
				this.setState({users}, () => {resolve({status: 'success'});});
			},
			(error) => {
				console.error('could not get users: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Toggle create game popup
	 */
	toggleCreateGamePopup = () => {
		let showCreateGamePopup = !this.state.showCreateGamePopup;
		this.setState({showCreateGamePopup});
	}

	/**
	 * Create game
	 */
	handleCreateGame = (gameTitle) => {
		if (this.state.isCreatingGame || this.state.isCreatingGame) return;

		const promise = new Promise((resolve) => {
			this.setState({isCreatingGame: true}, () => {
				apiHelper('facilitator/create-game', {
					userId: this.props.userId, 
					gameTitle, 
				}).then(
					(success) => {
						// Success
						this.setState({isCreatingGame: false, showCreateGamePopup: false}, () => {
							resolve({ status: 'success', gameId: success.gameId});
						});
					},
					(rejection) => {
						// Error
						console.error(rejection);
						this.setState({isCreatingGame: false}, () => {
							resolve({ status: 'failed'});
						});
					}
				);
			});
		});

		return promise;
	}

	/**
	 * Toggle delete game popup
	 * @param {string} gameId 
	 */
	toggleDeleteGamePopup = (gameId) => {
		let showDeleteGamePopup = (gameId ? true : false);
		this.setState({showDeleteGamePopup, gameId});
	}

	/**
	 * Delete game
	 * @param {string} gameId 
	 */
	handleDeleteGame = (gameId) => {
		if (this.state.isCreatingGame || this.state.isDeletingGame) return;
		this.setState({isDeletingGame: true}, () => {
			apiHelper('facilitator/delete-game', {
				userId: this.props.userId,
				gameId: gameId,
			}).then(
				() => {
					// Success
					this.setState({isDeletingGame: false, showDeleteGamePopup: false});
				},
				(rejection) => {
					// Error
					console.log(rejection);
					this.setState({isDeletingGame: false});
				}
			);
		});
	}
	

	/**
	* Render component
	*/
	render() {
		/* Settings page */
		return (
			<Settings			
				showDeleteGamePopup={this.state.showDeleteGamePopup}
				isDeletingGame={this.state.isDeletingGame}
				gameId={this.state.gameId}
				currentGame={this.props.games[0]}
				userData={this.props.userData}
				users={this.state.users}
				toggleDeleteGamePopup={this.toggleDeleteGamePopup}
				handleCreateGame={this.handleCreateGame}
				handleDeleteGame={this.handleDeleteGame}
				handleGoToGame={this.props.handleGoToGame}
				handleLogout={this.props.handleLogout} 
			/>
		);
	}
}

SettingsController.propTypes = {
	userId: PropTypes.string.isRequired,
	userData: PropTypes.object,
	games: PropTypes.array.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default SettingsController;
